const WorkExperienceJSON = {
    "workExp": [
        {
            "company": "Netmeds",
            "icon": "/images/companies/netmeds.png",
            "description": "Netmeds is a online pharmacy in India and a subsidiary of Reliance Retail",
            "website": "",
            "start_date": "2022, OCT",
            "end_date": "2023, JUL",
            "location": "CHENNAI, INDIA",
            "role": "Sr. Development Manager",
            "responsibilities": [
                "Manange the Trinity & Inventory Cache development teams",
                "End-to-End Delivery of projects via sprints",
                "Provide technical leadership and mentorship to the team",
                "Estabilsh a hiring strategy complete with hiring plan and several evaluation templates",
                "Create tech organizations OKRs & KPIs",
                "Promote Agile, SCRUM & Kanban methodologies"
            ],
            "projects": [
                "Implement logic for to distribute stock among the B2C, B2B and B2B2C channels",
                "Forming the SCM team and setting up a roadmap for the same",
                "Develop several feature requests and bug fixes for the Inventory Cache"
            ],
            "technologies": [
                ".NET",
                "C#",
                "Kafka",
                "Redis",
            ],
            "databases": [
                "MySQL"
            ],
            "cloudservices": ["Azure", "GCP"],
            "tools": [
                "Jira",
                "Confluence",
                "Slack",
                "Bit Bucket",
                "Visual Paradigm",
                "Micosoft Office",
                "Google Docs"
            ]
        },
        {
            "company": "NINJACART",
            "icon": "/images/companies/ninjacart.png",
            "description": "Ninjacart develops products/services for the Agriculture supply chain in India & clients abroad as well",
            "website": "https://www.ninjacart.com/",
            "start_date": "2021, OCT",
            "end_date": "2022, SEP",
            "location": "BENGALURU, INDIA",
            "role": "DIRECTOR OF ENGINEERING",
            "responsibilities": [
                "Design and Develop Products",
                "Manage development teams and delivery",
                "Monitor & Support production systems & operations, Lead Production Support team",
                "Lead Delivery Manager for all client partnerships in the B2B business model - supporting pre-sales, sales, onboarding new clients, deployments, customization, and LTS.",
                "Complete ownership of Ninjacart's IT/DevOps for all products/services.",
                "Lead the auditing team to perform ISO, KPMG, InfoSec, VAPT audits and certifications."
            ],
            "projects": [
                "Develop ground-up a microservices platform for the new business model - a pure tech-driven supply chain system. Several functional services and core infra services were developed.",
                "Support the current business model and products/services in the supply and demand system.",
                "Take ownership of all client partnerships in the B2B business model - supporting pre-sales, sales, onboarding new clients, deployments, customization, and LTS.",
                "Complete ownership of Ninjacarts IT/DevOps/Production Support for all products/services."
            ],
            "technologies": [
                "Java",
                "NodeJS",
                "Kafka",
                "Ubuntu",
                "Docker",
                "Kubernetes",
                "Jenkins"

            ],
            "databases": [
                "MySQL",
                "MongoDB",
                "Redis"
            ],
            "cloudservices": [
                "Hetzner",
                "AWS",
                "Azure"
            ],
            "tools": [
                "ElasticSearch",
                "Kibana",
                "Grafana",
                "Prometheus",
                "BitBucket",
                "JIRA",
                "Confluence",
                "Slack",
                "New Relic",
                "PagerDuty"

            ]
        },
        {
            "company": "INSTANCY",
            "icon": "/images/companies/instancy.png",
            "description": "Instancy develops products/services for the EdTech industry in the US & clients abroad",
            "website": "https://www.instancy.com/",
            "start_date": "2021, APR",
            "end_date": "2021, SEP",
            "location": "CHENNAI, INDIA",
            "role": "CHIEF TECHNOLOGY OFFICER",
            "responsibilities": [
                "Product & Technology - Enhance the Product - Features, Pilot Projects, Tech debt Projects",
                "Lead the Product Team",
                "Lead the UX/UI Designs Team",
                "Lead UI Development Team",
                "Lead Development Team",
                "Lead QA Team",
                "Lead DevOps Team",
                "Release Management.",
                "Manage Operations - Customer Service, HR, Tech Writing, and IT."
            ],
            "projects": [
                "Develop & enhance an EdTech Platform",
                "Improve performance of existing product",
                "Develop a Course Recommendation Engine",
                "Establish DevOps process using Azure DevOps. All stages from planning sprints, creating Git artifacts, to CI/CD Pipelines and Automated Deployment."
            ],
            "technologies": [
                ".NET Core 3.1",
                "ASP.NET Core",
                "C#",
                "Angular",
                "Webpack",
                "LUIS & Microsft Q&A Maker",
                "Google Lighthouse",
                "Python",
                "TensorFlow",
                "Recommendation Engine",
                "Knowledge Graph",
                "CI/CD Pipelines",
                "Automated Deployment"
            ],
            "databases": [
                "Sql Server",
                "Redis"
            ],
            "cloudservices": [
                "Azure",
                "Azure Cognitive Services",
                "Azure Logic Apps",
                "Azure Functions",
                "Azure App Services",
                "Azure SQL",
                "Azure Blob Storage",

                "Azure Machine Learning"
            ],
            "tools": [
                "Azure DevOps",
                "Azure Monitor",
                "Azure Application Insights",
                "Azure Log Analytics",
                "Git",
            ]
        },
        {
            "company": "SULEKHA",
            "icon": "/images/companies/sulekha.png",
            "description": "Sulekha is a digital platform for local service businesses in India that also developed an EdTech platform",
            "website": "https://www.sulekha.com/, https://www.capshine.com/",
            "start_date": "2020, MAR",
            "end_date": "2021, APR",
            "location": "CHENNAI, INDIA",
            "role": "SENIOR DIRECTOR OF TECHNOLOGY",
            "responsibilities": [
                "Managing Projects - Ideating, Feature Specification, Planning, Executing, Tracking and Delivery.",
                "Established DevOps process using Azure DevOps. All stages from planning sprints, creating Git artifacts, to CI/CD Pipelines and Automated Deployment.",
                "Established tech design and coding principles and best practices.",
            ],
            "projects": [
                "Developed an EdTech platform elearn.sulekha.com from the ground up."
            ],
            "technologies": ["React", "VueJS", "Vuetify", ".NET Core", "ASP.NET Core", "NodeJS", "React Native", "Cordova"],
            "databases": ["SQL", "Cosmos DB"],
            "cloudservices": ["Azure services"],
            "tools": [
                "Azure DevOps",
                "Azure Monitor",
                "Azure Application Insights",
                "Azure Log Analytics",
                "Git",
            ]
        },
        {
            "company": "RESULTICKS",
            "icon": "/images/companies/resulticks.png",
            "description": "Resulticks is a omni-channel marketing automation platform for the digital marketing industry",
            "website": "https://www.resulticks.com/",
            "start_date": "2019, OCT",
            "end_date": "2020, MAR",
            "location": "CHENNAI, INDIA",
            "role": "ASSOCIATE DIRECTOR, DELIVERY",
            "responsibilities": [
                "Managing the Solutioning team Architects, .NET, and SQL/NoSQL developers.",
                "Traveling to client locations and gathering requirements during workshops.",
                ", MySQL, SQL Server, Azure, and AWS."
            ],
            "projects": [
                "Developed a Marketing Automation Platform",
                "Customized the Marketing Automation Platform for clients like Tata, HDFC, and several others."
            ],
            "technologies": [
                "React",
                ".NET",
                "C#",
                "ASP.NET MVC",
                "WCF",
                "Windows Services",
            ],
            "os": [],
            "databases": [
                "MySQL",
                "SQL Server"
            ],
            "cloudservices": [
                "Azure",
                "AWS"
            ],
            "tools": [
                "Microsoft Office",
                "Visual Paradigm",
                "Azure Monitor",
                "Azure Application Insights",
                "Azure Log Analytics",
                "Git"
            ]
        },
        {
            "company": "ANTWORKS",
            "icon": "/images/companies/antworks.jpg",
            "description": "AntWorks is a global AI and intelligent automation company",
            "website": "https://www.ant.works/",
            "start_date": "2018, JUN",
            "end_date": "2019, OCT",
            "location": "CHENNAI, INDIA",
            "role": "AVP - DELIVERY MANAGER",
            "responsibilities": [
                "Delivery Head: Manage multiple projects and products from development through delivery and support.",
                "Responsible for end-to-end project delivery from requirements gathering to production and support.",
                "Manage teams and delivery using Agile, SCRUM methodology. JIRA agile tool is used for all project management.",
                "Interacting with the Client Services team, Solution Architecture, Product Management, Product Implementation, QMS, QA, and Program Management teams.",
                "Responsible for Componentizing (around 160 components) the entire product code base.",
                "Responsible for team building, goal settings, and performance appraisals.",
                "Developed a search engine based on Apache Lucene. Experienced in rules management solutions like JBoss and NRules.NET.",
                "Experienced in AWS cloud technologies like VPC, EC2, SS3, RDS, Auto scaling, and HA.",
                "End-to-end development of products using .NET Framework, C#, ASP.NET MVC, Angular, Python, TensorFlow, PyTorch, OCR, SQL Server, AWS."
            ],
            "projects": [
                "Developed a Cognitive Machine Reading Platform Prototype"
            ],
            "technologies": [
                ".NET Core",
                "Angular",
                "Azure Blob Storage",
                "Lucene",
                "Python",
                "TensorFlow",
                "PyTorch",
                "OCR"
            ],
            "databases": [
                "SQL Server",
            ],
            "cloudservices": [
                "Azure",
                "AWS"
            ],
            "tools": [
                "Microsoft Office",
                "Visual Paradigm",
                "Git"
            ]
        },
        {
            "company": "IQVIA",
            "icon": "/images/companies/iqvia.jpg",
            "description": "IQVIA is a global provider of information, innovative technology solutions, and contract research services focused on using data and science to help healthcare clients find better solutions for their patients",
            "website": "https://www.iqvia.com/",
            "start_date": "2015, JAN",
            "end_date": "JUN, 2018",
            "location": "CHENNAI, INDIA",
            "role": "SOFTWARE MANAGER",
            "responsibilities": [
                "Manage multiple projects from development through delivery and support.",
                "Manage and develop iPhone, Android mobile apps developed using Xamarin, C#.",
                "Develop and take ownership of the core proprietary ETL engine. The engine supports import of data from databases, documents into proprietary cubes displayed in the IQ Reporting engine.",
                "Develop and take ownership of the Dashboard module, a main component in the IQ Services website, www.Iq2020.com.",
                "Establish Agile, SCRUM methodology."
            ],
            "projects": [
                "Develop a Mobile App the IQ 20/20 Sales Reporting System",
                "Improve performance of the core IQ 20/20 ETL engine",
                "Develop a UI for the IQ 20/20 ETL engine"
            ],
            "technologies": [

                "C#",
                ".NET",
                "Xamarin",
                "HTML",
                "CSS",
                "Javascript"
            ],
            "databases": ["SQL Server", "Propreitary ETL Engine", "Postgres"],
            "cloudservices": [],
            "tools": []
        },
        {
            "company": "ISGN",
            "icon": "/images/companies/isgn.jpg",
            "description": "ISGN is a leading provider of mortgage technology and services",
            "website": "https://www.isgn.com/",
            "start_date": "2014, SEP",
            "end_date": "2015, JAN",
            "location": "CHENNAI, INDIA",
            "role": "VICE PRESIDENT - ENGINEERING",
            "responsibilities": [
                "Delivery Management of Tempo product in default servicing area in the mortgage domain.",
                "Managing and Leading a team of 70 members. Providing planning, direction, and coordination.",
                "Interacting with Solution Architecture, Product Management, Product Implementation, QMS, QA, Program Management, etc. teams.",
                "Managing teams & delivery using Agile, SCRUM methodology. JIRA was used for all Project Management related tasks."
            ],
            "projects": [
                "Developed a Mortgage Servicing Platform"
            ],
            "technologies": [
                ".NET Framework",
                "C#",
                "ASP.NET",
            ],
            "os": [],
            "databases": [
                "SQL Server",
                "Postgres"
            ],
            "cloudservices": [
                "AWS SQS",
                "AWS S3",
                "AWS EC2"
            ],
            "tools": [
                "Microsoft Office",
                "Visual Paradigm",
                "JIRA",
                "MS Team Foundation Server"
            ]
        },
        {
            "company": "Global Market Intelligence",
            "icon": "/images/companies/spcapital.jpg",
            "description": "S&P Capital IQ is a leading provider of multi-asset class and real-time data, research, and analytics",
            "website": "https://www.spcapitaliq.com/",
            "start_date": "2011, JAN",
            "end_date": "2014, JUN",
            "location": "HYDERABAD, INDIA",
            "role": "CHIEF ARCHITECT",
            "responsibilities": [
                "As a Chief Architect I was responsible for establishing best architectural & code practices, imporve processes, and mentor the team.",
                "Develop, Design, and Code Review for all the major projects.",
                "Team Building - Mentoring & guiding a team of 300+ developers in the areas of Tech design and coding.",
                "Implementation of the release management process end to end including change management.",
                "Responsible for the entire architecture of the content collection systems from Client/Server to 3-Tier, Distributed and SOA architectures based on Business Use Cases.",],
            "projects": [
                "Re-architecture of content collection systems from Client/Server to 3-Tier, Distributed and SOA architectures based on Business Use Cases.",
                "Auto Extraction - Extracting (financials) data from unstructured documents in various file formats like PDF, XML, HTML, Txt, RTF etc.",
                "Auto Conversion - A framework to perform the conversion of documents from one format to the other.",
                "Developed a Server Process Library that is a Self-Hosting Deployment Solution built on top of WCF, to deploy app servers supporting various types of document extraction requests."
            ],
            "technologies": [
                ".NET Framework",
                "C#",
                "ASP.NET",
                "WCF",
                "PDF Miner",
                "Stanford NLP Parser",
            ],
            "databases": [
                "SQL Server",
                "Cassandra"
            ],
            "cloudservices": [],
            "tools": [
                "PDF Miner",
                "Stanford NLP Parser",
                "App Fabric",
                "IIS",
                "PowerShell"
            ]
        },
        {
            "company": "CREDIT-SUISSE",
            "icon": "/images/companies/credit-suisse.png",
            "description": "Credit Suisse is a leading global wealth manager with strong investment banking capabilities",
            "website": "https://www.credit-suisse.com/",
            "start_date": "2009, AUG",
            "end_date": "2011, JAN",
            "location": "SINGAPORE",
            "role": "DELIVERY MANAGER/DEVELOPER",
            "responsibilities": [
                "Delivery Manager for 2 teams, the booking system and reporting system, for a combined team size of 20 developers, BAs, QAs, and DBAs.",
                "Gathering requirements by conducting regular stakeholder meetings and business analysts.",
                "Developed tools like Log to Excel Exporter, Booking Statistics, Reporting Statistics, Visual Debugger, Order replay, Volume trader in C#, .NET, ASP.NET, VC++.",
                "Actively participated in production support by both systems monitoring and troubleshooting client issues daily.",
                "Played the role of Architect for the Front Office Systems.",
                "Involved in enhancing the reporting system.",
                "Responsible for scaling up the entire system for 7x capacity."
            ],
            "projects": [
                "Developed a Booking System",
                "Developed a Reporting System",
                "Developed a Log to Excel Exporter",
                "Developed a Visual Debugger, Order replay, Volume trader",
                "Developed an internal Unified Tooling Interface, which is a single interface for all the tools used by the developers"
            ],
            "technologies": ["C#", ".NET", "ASP.NET", "VC++", "Java", "J2EE", "Java Servlets", "JSP", "EJB", "JMS", "JNDI", "JDBC", "XML", "XSLT", "JAXP", "JAX-RPC"],
            "databases": ["Oracle", "SQL Server", "Agora Marketplace"],
            "tools": ["Microsoft Office", "Visual Paradigm", "JIRA", "MS Team Foundation Server"]
        },
        {
            "company": "INDEPENDENT CONSULTANT",
            "icon": "/images/companies/freelancer.jpg",
            "description": "Worked as an Independent Consultant",
            "website": "rameshv.me",
            "start_date": "2006, FEB",
            "end_date": "2009, JUN",
            "location": "Chennai, India",
            "role": "FREELANCER",
            "clients": "Lister Technologies, Athenanet, Tejas Soft, Indepth, GiftPay",
            "responsibilities": [
                "Bid for Projects",
                "Develop Projects",
                "Delivery and Support"
            ],
            "projects": [
                "Developing a Gift Card portal with buy, sell and auction sides for a client in the US.",
                "Worked on Modules integration systems, to extend the CMS for a client in Chile.",
                "Worked on a loan processing system with an underwriting engine for a client in New York, US.",
                "Developed a CV portal using for a client in Canada.",
                "Developed export functionality in a BI product for a company called In Depth Web in Pennsylvania, US.",
                "Developing a generic resourcing engine (GRE) for Lister Technologies, a client in Chennai.",
                "Developed a complete Organization management system using the generic resourcing engine mentioned above.",
                "Worked on a System Stabilization project for AthenNetIndia, Chennai.",
                "Worked on a Business Intelligence portal for In Depth web technologies, Chennai.",
                "Worked for Tejas soft, US on advance payroll system."
            ],
            "technologies": [
                "ASP.NET 2.0",
                "C#",
                "Telerik",
                "AJAX",
                "SiteFinity 3.1",
                "Nolics 2005",
                "JBoss",
                "Rules ML",
                "PHP",
                "Java",
                "J2EE",
                "Java Servlets",
                "JSP",
                "EJB",
                "SyncFusion",
                ".NET",
                "JDBC",
                "XML",
                "XSLT"
            ],
            "os": [],
            "databases": ["MySQL", "SQL Server", "Oracle", "Postgres"],
            "cloudservices": [],
            "tools": [
                "Microsoft Office",
                "Visual Studio",
                "IIS"
            ]
        },
        {
            "company": "SOFTWARE SOLUTIONS INTEGRATED",
            "icon": "/images/companies/ssi.png",
            "description": "SSI is a software consuting services company",
            "website": "",
            "start_date": "2000, MAR",
            "end_date": "2003, APR",
            "location": "CHENNAI, INDIA",
            "role": "TEAM LEAD",
            "responsibilities": [
                "Lead for a team of BAs and developers. Responsible for system studies and proposal/prototype development.",
                "Proposal and prototype development for potential SSI Clients like IATA< MTNL, BCE, and others.",
                "Team lead for a small development team."
            ],
            "projects": [
                "Proviso Composer, IATA, Geneva",
                "Market Administration and Control module at Bombay Commodity Exchange"
            ],
            "technologies": [
                "HTML",
                "CSS",
                "javascript",
                "XML",
                "XSD",
                "XSLT",
                "C++",
                "DHTML",
                "JS",
                "CGI",
                "C",
                "DEC MessageQ API",
                "Tandem OS",
                "IBM OS/2",
                "Windows NT",
                "Windows 95"
            ],
            "databases": ["Tandem SQL", "DB2"],
            "tools": ["Microsoft Office", "Rational Rose UML", "VSS"]

        },
        {
            "company": "SOFTWARE SYSTEMS INTEGRATED",
            "icon": "/images/companies/ssi2.jpg",
            "description": "SSI is a head hunting & consulting services company",
            "website": "",
            "start_date": "1996, DEC",
            "end_date": "2000, JAN",
            "location": "UNITED STATES",
            "role": "PROGRAMMER/CONSULTANT",
            "responsibilities": [
                "Programming",
                "Consulting",
                "IT Support & develop automated deployments for Professional Services",
            ],
            "projects": [
                "Developed a multimedia indexing/search and streaming functions",
                "Electronic signature sub-system",
                "Custom network messaging interface between IBM servers/clients",
                "Developed a freelance website for elance.com"
            ],
            "technologies": [
                "DHTML",
                "JS",
                "CGI",
                "C",
                "C++",
                "Korn Shell",
                "Perl",
                "DB2",
                "PHP",
                "MySQL",
                "HTML",
                "CSS"
            ],
            "databases": [
                "Oracle",
                "Sybase",
                "Informix"
            ],
            "cloudservices": [],
            "tools": ["Rational Rose UML", "VSS"]
        }
    ]
}

function getWorkExperienceJSON() {
    return WorkExperienceJSON;
}

export default getWorkExperienceJSON; 
