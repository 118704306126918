import React, { Component } from 'react';

import AppHeader from '../AppHeader/AppHeader';
import AppBody from '../AppBody/AppBody';

import './AppProgrammingServices.css';
import './AppMain.css';

class AppProgrammingServices extends Component {

  render() {
    return (
      <div className="AppMain">
        <AppHeader />
        <AppBody activeTab='tab1' activeService="programmer"/>
      </div>
    );
  }
}

export default AppProgrammingServices;