const FreeTemplatesJSON = {
    "data": [
        {
            "title": "Technical Templates",
            "list": [
                {
                    "title": "DBServer-Sizing-Template",
                    "link": "./files/templates/tech/DBServer-Sizing-Template.xlsx",
                    "description": "Template to determine optimal sizing for database server infrastructure."
                },
                {
                    "title": "Technical Design Doc Template",
                    "link": "./files/templates/tech/Technical Design Doc Template.dotx",
                    "description": "Template for comprehensive technical design documentation for projects."
                },
                {
                    "title": "Systems Decomposition Structure Template",
                    "link": "./files/templates/tech/Systems Decomposition Structure Template.xlsx",
                    "description": "Template to organize and structure system decomposition in projects."
                },
                {
                    "title": "System Release Planner Template",
                    "link": "./files/templates/tech/System Release Planner Template.xlsx",
                    "description": "Template for planning and scheduling system releases effectively."
                }
            ]
        },
        {
            "title": "HR Templates",
            "list": [
                {
                    "title": "JD Template",
                    "link": "./files/templates/hr/JD Template.pdf",
                    "description": "Template for creating detailed job descriptions for various positions."
                },
                {
                    "title": "Tech Interview Evaluation Sheet Template",
                    "link": "./files/templates/hr/Tech Interview Evaluation Sheet Template.xlsx",
                    "description": "Template for evaluating technical skills and performance in job interviews."
                },
                {
                    "title": "HR Evaluation Sheet Template",
                    "link": "./files/templates/hr/HR Evaluation Sheet Template.xlsx",
                    "description": "Template for assessing and evaluating HR-related criteria and performance."
                },
                {
                    "title": "HR Overall Evaluation Form Template",
                    "link": "./files/templates/hr/HR Overall Evaluation Form Template.xlsx",
                    "description": "Template for comprehensive evaluation of HR performance and outcomes."
                }
            ]
        },
        {
            "title": "Management Templates",
            "list": [
                {
                    "title": "Resource Capacity & Planning Template",
                    "link": "./files/templates/manage/Resource Capacity & Planning Template.xlsx",
                    "description": "Template for planning and managing resource capacity effectively."
                },
                {
                    "title": "Team-Goal Setting and Performance Objectives Template",
                    "link": "./files/templates/manage/Team-Goal Setting and Performance Objectives Template.xlsx",
                    "description": "Template for setting and tracking team goals and performance objectives."
                },
                {
                    "title": "TNI Skill Framework Template",
                    "link": "./files/templates/manage/TNI Skill Framework Template.xlsx",
                    "description": "Template for establishing and managing skill frameworks for training needs identification."
                },
                {
                    "title": "Team Competencies Template",
                    "link": "./files/templates/manage/Team Competencies Template.xlsx",
                    "description": "Template for defining and assessing team competencies and skill sets."
                }
            ]
        },
        {
            "title": "Project Templates",
            "list": [
                {
                    "title": "WBS-Estimation-Excel-Template",
                    "link": "./files/templates/project/WBS-Estimation-Excel-Template.xlsx",
                    "description": "Template for estimating project effort and scope using WBS in Excel."
                },
                {
                    "title": "WSR - Template",
                    "link": "./files/templates/project/WSR - Template.xlsx",
                    "description": "Template for creating weekly status reports to track project progress."
                },
                {
                    "title": "Patch Request Template",
                    "link": "./files/templates/project/Patch Request Template.docx",
                    "description": "Template for submitting and tracking patch requests in project development."
                },
                {
                    "title": "Weekly-Project-Status Template",
                    "link": "./files/templates/project/Weekly-Project-Status Template.xlsx",
                    "description": "Template for tracking and reporting weekly project status and updates."
                }
            ]
        },
        {
            "title": "Training Templates",
            "list": [
                {
                    "title": "Tech Team Training Tracker Template",
                    "link": "./files/templates/training/Tech Team Training Tracker Template.xlsx",
                    "description": "Template for tracking technical team training sessions and progress."
                }
            ]
        },
        {
            "title": "Incident Templates",
            "list": [
                {
                    "title": "Incident (postmortem) Template",
                    "link": "./files/templates/incident/Incident-postmortem-Template.pdf",
                    "description": "Template for conducting postmortem analysis and reporting on incidents."
                },
                {
                    "title": "RCA Template",
                    "link": "./files/templates/incident/RCA Template.pdf",
                    "description": "Root Cause Analysis (RCA) template for identifying and addressing incident causes."
                }
            ]
        }
    ]
}

function getFreeTemplatesJSON() {
    return FreeTemplatesJSON;
}

export default getFreeTemplatesJSON;