const EducationJSON = {
    "data": [
        {
            "degree": "MS",
            "field_of_study": "Computer Science",
            "university": "West Chester University of Pennsylvania",
            "year": "1994 - 1996",
            "location": "United States",
            "icon": "/images/edu/wcuSeal.png"
        },
        {
            "degree": "B. Tech",
            "field_of_study": "Chemical Engineering",
            "university": "Indian Institute of Technology, Madras",
            "year": "1990 - 1994",
            "location": "India",
            "icon": "/images/edu/IIT_Madras_Logo.png"
        },
        {
            "degree": "CBSE",
            "field_of_study": "Senior Secondary",
            "university": "Padma Seshadri Bala Bhavan SSS",
            "year": "1986 - 1990",
            "location": "India",
            "icon": "/images/edu/psbb.png"
        }
    ]
}

function getEducationJSON() {
    return EducationJSON;
}

export default getEducationJSON;

