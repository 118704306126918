const techDiagramsJSON = {
    "data": [
        {
            "topic": "Claims Middleware",
            "description": "The eCommerce Pharma system comprises components for browsing, adding medicines to cart, checkout, and placing orders. The Claims Middleware manages claims raised by TPAs' customers, processing and delivering medicines. It integrates third-party assurers, enabling their clients to order drugs via claims, ensuring complete system reuse.", 
            "diagrams": [
                {
                    "diagram": "claims middleware.png",
                    "diagram_type": "UML Component Diagram",
                    "description": "The integration between components of the 3rd party insurer system and Pharma eCOmmerce back-end is depicted",
                    "link": "/files/tech-diagrams/claims/claims middleware.png"
                },
                {
                    "diagram": "claims Use case diagram.png",
                    "diagram_type": "UML Use Case Diagram",
                    "description": "The use case diagram for the claims middleware is depicted. The use cases are the various functionalities that the middleware will provide to TPAs and to their users.",
                    "link": "/files/tech-diagrams/claims/claims Use case diagram.png"
                },
                {
                    "diagram": "Insurance ER diagram.png",
                    "diagram_type": "ER Diagram",
                    "description": "Following are the basic tables that are required to store the data for the claims",  
                    "link": "/files/tech-diagrams/claims/Insurance ER diagram.png"
                },
                {
                    "diagram": "main flow diagram.png",
                    "diagram_type": "UML Activity Diagram",
                    "description": "The main flow aournd the claims middleware is depicted in this diagram",
                    "link": "/files/tech-diagrams/claims/main flow diagram.png"
                }
            ]
        },
        {
            "topic": "General",
            "description": "A collection of different types of diagrams.",
            "diagrams": [
                {
                    "diagram": "auth-v3 class diagram.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/general/auth-v3 class diagram.jpg"
                },
                {
                    "diagram": "common exception handling class diagram.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/general/common exception handling class diagram.jpg"
                },
                {
                    "diagram": "common presistance class diagram.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/general/common presistance class diagram.jpg"
                },
                {
                    "diagram": "DevOps.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/general/DevOps.jpg"
                },
                {
                    "diagram": "Exception Handling Sequence Diagram.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/general/Exception Handling Sequence Diagram.jpg"
                },
                {
                    "diagram": "microservices arch.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/general/microservices arch.jpg"
                },
                {
                    "diagram": "Ninjacart Network Topology .jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/general/Ninjacart Network Topology .jpg"
                },
                {
                    "diagram": "RT Cache replication Class diagram.png",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/general/RT Cache replication Class diagram.png"
                }
            ]
        },
        {
            "topic": "Inter-VPC Kafka",
            "description": "This design provides multiple options for to connect kafka producer and consumer across VPCs securely.",
            "diagrams": [
                {
                    "diagram": "Consumer Config ER.png",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/inter-vpc-kafka/Consumer Config ER.png"
                },
                {
                    "diagram": "Inter VPC Kafka Arch Producer Side.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/inter-vpc-kafka/Inter VPC Kafka Arch Producer Side.jpg"
                },
                {
                    "diagram": "Inter VPC Kafka Arch Producer Side.png",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/inter-vpc-kafka/Inter VPC Kafka Arch Producer Side.png"
                },
                {
                    "diagram": "inter VPC Kafka on Consumer Side.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/inter-vpc-kafka/inter VPC Kafka on Consumer Side.jpg"
                },
                {
                    "diagram": "inventory component diagram.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/inter-vpc-kafka/inventory component diagram.jpg"
                },
                {
                    "diagram": "IS Consumer Sequence diagram.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/inter-vpc-kafka/IS Consumer Sequence diagram.jpg"
                }
            ]
        },
        {
            "topic": "Payments",
            "description": "This design depicts the workflow of the payments microservice with the transaction & ledger microservices. It also shows the interaction overview diagram.",
            "diagrams": [
                {
                    "diagram": "activity-diagram-Payment Service To Ledger Workflow.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/payments/activity-diagram-Payment Service To Ledger Workflow.jpg"
                },
                {
                    "diagram": "Interaction Overview Diagram1.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/payments/Interaction Overview Diagram1.jpg"
                }
            ]
        },
        {
            "topic": "Reqlaim",
            "description": "Reqlaim is a centralized customer care centre across any suite of products for any customer. The diagrams depict how to intercept and process the customer requests.",
            "diagrams": [
                {
                    "diagram": "Communication Diagram1.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/reqlaim/Communication Diagram1.jpg"
                },
                {
                    "diagram": "Reqlaim-Activity Diagram1.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/reqlaim/Reqlaim-Activity Diagram1.jpg"
                },
                {
                    "diagram": "State Machine Diagram1.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/reqlaim/State Machine Diagram1.jpg"
                }
            ]
        },
        {
            "topic": "Unity",
            "description": "Unity is as the name suggests is a unified search interface that aggregates search results from multiple sources like elastic search, prometheus, new relic, git commits, jira, automated QA tests, deployment logs etc.",
            "diagrams": [
                {
                    "diagram": "unity-different search use case diagram.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/unity/unity-different search use case diagram.jpg"
                },
                {
                    "diagram": "unity-info and data flow activity diagram.jpg",
                    "diagram_type": "Image",
                    "description": "",
                    "link": "/files/tech-diagrams/unity/unity-info and data flow activity diagram.jpg"
                }
            ]
        }
    ]
};    

function getTechDiagramsJSON() {
    return techDiagramsJSON;
}

export default getTechDiagramsJSON;