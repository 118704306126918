import React, { Component } from 'react';

import getlangaugesJSON from './data/LanguagesJSON';

import '../AppBodyCommon.css'
import '../Resume.css';
import './Languages.css';

class Languages extends Component {

  render() {
    return (
      <div className='app-tab-item-container'>
        <div className='app-tab-item-container-items double-extra-top'>
          <div className='app-tab-item-container-item'>
            <div className='lang'>
              <table border='1' cellPadding='8px' className='lang-table'>
                <thead>
                  <tr className="lang-header-row">
                    <td>Language</td>
                    <td>Speak</td>
                    <td>Read</td>
                    <td>Write</td>
                  </tr>
                </thead>
                <tbody>
                  {getlangaugesJSON().data.map((node, index) => (
                    <tr key={index} className='lang-row-item'>
                      <td className='lang-text-special'>{node.langauge}</td>
                      <td className='lang-text'>{node.speak === true ? (<i className="fas fa-check" style={{ color: 'blue', paddingRight: '4px' }}></i>) : (<i className="fas fa-times" style={{ color: 'red', paddingRight: '4px' }}></i>)}</td>
                      <td className='lang-text'>{node.read === true ? (<i className="fas fa-check" style={{ color: 'blue', paddingRight: '4px' }}></i>) : (<i className="fas fa-times" style={{ color: 'red', paddingRight: '4px' }}></i>)}</td>
                      <td className='lang-text'>{node.write === true ? (<i className="fas fa-check" style={{ color: 'blue', paddingRight: '4px' }}></i>) : (<i className="fas fa-times" style={{ color: 'red', paddingRight: '4px' }}></i>)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default Languages;

