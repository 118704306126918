const GuidelinesJSON = {
    "data": [
        {
            "title": "Technical",
            "list": [
                {
                    "title": "SQL guidelines",
                    "link": "./files/guidelines/tech/SQL guidelines.pdf",
                    "description": "Best practices and guidelines for SQL query optimization techniques."
                },
                {
                    "title": "Design best practices guidelines",
                    "link": "./files/guidelines/tech/Design best practices guidelines.docx",
                    "description": "Guidelines for implementing design best practices in software development."
                },
                {
                    "title": "Coding Standards guidelines",
                    "link": "./files/guidelines/tech/Coding Standards guidelines.docx",
                    "description": "Standards and conventions for coding practices and consistency in development."
                },
                {
                    "title": "Cosmetic UI Aspects guidelines",
                    "link": "./files/guidelines/tech/Cosmetic UI Aspects guidelines.docx",
                    "description": "Guidelines for enhancing user interface appearance and aesthetics in applications."
                },
                {
                    "title": "QA Automation Framework guidelines",
                    "link": "./files/guidelines/tech/QA Automation Framework guidelines.docx",
                    "description": "Guidelines for implementing automated testing frameworks for quality assurance."
                },
                {
                    "title": "Re-Engineering guidelines",
                    "link": "./files/guidelines/tech/Re-Engineering guidelines.docx",
                    "description": "Guidelines for re-engineering and modernizing legacy systems and applications."
                },
                {
                    "title": "Workflow Module guidelines",
                    "link": "./files/guidelines/tech/Workflow Module guidelines.docx",
                    "description": "Guidelines for designing and implementing workflow modules in applications."
                },
                {
                    "title": "API naming guidelines",
                    "link": "./files/guidelines/tech/API naming guidelines.pdf",
                    "description": "Best practices and conventions for naming APIs and endpoints in development."
                },
                {
                    "title": "Code review guidelines",
                    "link": "./files/guidelines/tech/code review guidelines.pdf",
                    "description": "Guidelines for conducting effective code reviews for quality assurance."
                },
                {
                    "title": "DevOps guidelines",
                    "link": "./files/guidelines/tech/DevOps guidelines.pdf",
                    "description": "Best practices and strategies for implementing DevOps methodologies in projects."
                }
            ]
        },
        {
            "title": "Project Management",
            "list": [
                {
                    "title": "wbs effort estimate",
                    "link": "./files/guidelines/project/wbs effort estimate.pdf",
                    "description": "Estimation of effort using Work Breakdown Structure (WBS) technique."
                },
                {
                    "title": "agile methodology",
                    "link": "./files/guidelines/project/agile methodology.pdf",
                    "description": "Guidelines and practices for implementing agile methodologies in project management."
                },
                {
                    "title": "Jira guidelines",
                    "link": "./files/guidelines/project/Jira guidelines.pdf",
                    "description": "Best practices and tips for using Jira effectively in project management."
                },
                {
                    "title": "Tech Team Areas of Focus Guidelines",
                    "link": "./files/guidelines/project/Tech Team Areas of Focus Guidelines.pdf",
                    "description": "Guidelines for focusing on key areas of technology teams for success."
                }
            ]
        },
        {
            "title": "Architecture & Tech Design",
            "list": [
                {
                    "title": "tech design guidelines",
                    "link": "./files/guidelines/tech design/tech design guidelines.pdf",
                    "description": "Guidelines for designing and architecting technical solutions and systems."
                }
            ]
        },
        {
            "title": "Performance Optimization",
            "list": [
                {
                    "title": "SQL - Peformance Optimization Tips",
                    "link": "./files/guidelines/perf/SQL - Peformance Optimization Tips.xlsx",
                    "description": "Tips and techniques for optimizing SQL performance in database systems."
                },
                {
                    "title": "PerformanceTips-Programming",
                    "link": "./files/guidelines/perf/PerformanceTips-Programming.xlsx",
                    "description": "Tips and best practices for optimizing performance in programming techniques."
                },
                {
                    "title": "RDBMS - Performance Optimiation Tips",
                    "link": "./files/guidelines/perf/RDBMS - Performance Optimiation Tips.xlsx",
                    "description": "Tips and strategies for optimizing performance in relational database management systems."
                },
                {
                    "title": "System Performance Optimization guidelines",
                    "link": "./files/guidelines/perf/System Performance Optimization guidelines.docx",
                    "description": "Guidelines and strategies for optimizing system performance in applications."
                }
            ]
        },
        {
            "title": "HR",
            "list": [
                {
                    "title": "tech interview guidelines",
                    "link": "./files/guidelines/hr/tech interview guidelines.docx",
                    "description": "Guidelines for conducting effective technical interviews for hiring candidates."
                },
                {
                    "title": "hiring guidelines",
                    "link": "./files/guidelines/hr/hiring guidelines.pdf",
                    "description": "Guidelines and best practices for hiring and recruitment processes."
                }
            ]
        }
    ]
}

function getGuidelinesJSON() {
    return GuidelinesJSON;
}

export default getGuidelinesJSON;