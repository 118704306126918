import React, { Component } from 'react';

import './AppBodyInnerHeader.css';

class AppBodyInnerHeader extends Component {

  handleStateChangeFunction = (index) => {
    this.props.OnStateChange(index);
  }

  render() {
    var ComponentY = this.props.controlsComponent;

    return (
      <div className='app-body-inner-header'>
        <div className='app-body-inner-header-title'>{this.props.title}</div>
        <div className='app-body-inner-header-text' dangerouslySetInnerHTML={{ __html: this.props.summary }}></div>
        {ComponentY !== null && ComponentY !== undefined && (
          <div className='app-body-inner-header-controls'>
            <ComponentY activeTab={this.props.activeTab} data={this.props.data} OnStateChange={this.props.OnStateChange} />
          </div>
        )}
      </div>
    );
  }
}

export default AppBodyInnerHeader;