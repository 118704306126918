import React, { Component } from 'react';

import AppHeader from '../AppHeader/AppHeader';
import AppBody from '../AppBody/AppBody';

import './AppMain.css';

class AppMain extends Component {

  
   
  setURLPath = (tab) => {
    
    var url = window.location.origin;

    console.log("+++++++++in setURLPath on home with url: " + url);

    window.history.pushState({}, '', url);
  }

  componentDidMount() {
    this.setURLPath();
  }

  render() {
    return (

      <div className='AppMain'>
        <AppHeader />
        <AppBody />
      </div>
    );
  }
}

export default AppMain;