import React, { Component } from 'react';
import BulletedTree from '../../Controls/BulletedTree';
import RadioButtons from '../../Controls/RadioButtons';

import getContentCreatonOfferingsJSON from './data/contentCreatonOfferings';
import { getContentSamplesJSON } from './data/contentSamplesJSON';

import '../AppBodyCommon.css';
import '../Services.css';
import './TechContentCreationService.css';

import ContentSubjects from './ContentSubjects';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';

class TechContentCreationService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRBIndex: 0,
      technologiesJSON: []
    };
  }

  rbJson = [
    {"label": "Content Creation Offerings", "text": "Crafting compelling and informative content tailored to your unique needs."},
    {"label": "Sample Content", "text": "Explore Content showcasing expertise and quality of services and in various shapes & formats."},
    {"label": "Topics", "text": "Deepen your understanding of software development with expert insights."}
  ]
  
  titleStyle = {
    fontSize: '16px',
    //boxShadow: '2px 2px 2px 0px rgba(33, 150, 243, 0.8)',
    FontFace: 'Arial',
    padding: '2px 2px 2px 5px',
    boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
    borderRadius: '4px',
    fontVariant: 'small-caps',
    backgroundColor: 'rgba(250, 250, 212, 0.7)',
      paddingRight: '6px',
  paddingBottom: '4px'
}

subTitleStyle = {
  fontSize: '15px',
  FontFace: 'Arial',
  fontVariant: 'small-caps',
  padding: '2px 2px 2px 5px',
  boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
  borderRadius: '4px',
  marginTop:'8px',
  backgroundColor: 'rgba(250, 250, 60, 1)',
  width: 'fit-content',
    paddingRight: '6px',
  paddingBottom: '4px'
}

  subListStyle = { fontSize: '15px', paddingTop: '2px', paddingLeft: '2px'};

  subListStyle2 = { paddingTop: '2px', paddingLeft: '2px'};

  handleRadioButtonChange = (index) => {
    console.log("in handleRadioButtonChange " + index);

    this.setState({ selectedRBIndex: index });
  }

  render() {
    return (
      <div className='app-tab-item-container'>
        <div style={{width: '100%'}}>
          <RadioButtons data={this.rbJson} OnStateChange={this.handleRadioButtonChange} />
        </div>

        <div className='app-tab-item-container-items double-extra-top'>
          <div className={`${this.state.selectedRBIndex === 0 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <BulletedTree title="" data={getContentCreatonOfferingsJSON().data} titleStyle={this.titleStyle} subTitleStyle={this.subTitleStyle} subListStyle={this.subListStyle} showParentBullet={false}/>
          </div>
          <div className={`${this.state.selectedRBIndex === 1 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <BulletedTree title="" subListStyle={this.subListStyle2} showParentBullet={false} data={getContentSamplesJSON().data} />
          </div>
          <div className={`${this.state.selectedRBIndex === 2 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <ContentSubjects />
          </div>
        </div>
      </div>
    )
  }
};

export default TechContentCreationService;

