import React, { Component } from 'react';

import BulletedTree from '../../Controls/BulletedTree';

import getFreeTemplatesJSON from './data/FreeTemplatesJSON';

import '../AppBodyCommon.css';
import '../FreeContent.css';
import './FreeTemplates.css';

class FreeTemplates extends Component {
  subListStyle2 = { paddingTop: '2px', paddingLeft: '2px'};

  filterJSON = ["Technical Templates", "Incident Templates", "Project Templates", "Management Templates", "Training Templates", "HR Templates"];

  titleStyle = {
    fontSize: '15px',
    FontFace: 'Arial',
    fontVariant: 'small-caps',
    padding: '2px 2px 2px 5px',
    boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
    borderRadius: '4px',
    marginTop:'12px',
    marginBottom: '12px',
    backgroundColor: 'rgba(250, 250, 212, .7)',
    width: 'fit-content',
      paddingRight: '6px',
    paddingBottom: '4px'
  }
  
  render() {
    return (
      <div className='app-tab-item-container'>
        <div className='app-tab-item-container-items zero-top'>
          <div className='app-tab-item-container-item'>
            <BulletedTree title="" data={getFreeTemplatesJSON().data} anchorInNewWindow={false} filter={this.filterJSON} titleStyle={this.titleStyle} subListStyle={this.subListStyle2} showParentBullet={false}/>
          </div>
        </div>
      </div>
    )
  }
};

export default FreeTemplates;

