const techDesignsJSON = {
    "data": [
        {
            "title": "Tech Designs",
            "list": [
                {
                    "title": "eLearnScoringSystem.pdf",
                    "link": "./files/tech-designs/eLearnScoringSystem.pdf",
                    "description": "A scoring system that evaluate the performances of elearning courses, instructors & students. Helps to create adaptivve learning paths for students"
                },
                {
                    "title": "Star health claim booking - tech design.pdf",
                    "link": "./files/tech-designs/Star health claim booking - tech design.pdf",
                    "description": "Designs a middleware platform for health insurance providers to let their customers to purchase medicines by reusing the pharma ecommerce platform"
                },
                {
                    "title": "Tech Design - Knowledge Graph.pdf",
                    "link": "./files/tech-designs/Tech Design - Knowledge Graph.pdf",
                    "description": "This creates a knowledge graph database from the elearning course content avaialble or those will be created in the future. This knowledge graph will be used for several purposes like creating ML models for various use cases like learning paths, adaptive learning, coversational chatBOT interface, analytics etc,"
                }
            ]
        }
    ]
};

function getTechDesignsJSON() {
    return techDesignsJSON;
}

export { getTechDesignsJSON };