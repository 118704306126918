import React from 'react';

class CurrentTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: new Date(),
            culture: this.props.culture,
            timeZone: this.props.timeZone
        };
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({ time: new Date() });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return <div>{this.state.time.toLocaleTimeString(this.state.culture, { timeZone: this.state.timeZone})}</div>;
    }
}

export default CurrentTime;