const langaugesJSON = {
    "data": [
        {
            "langauge": "English",
            "speak": true,
            "read": true,
            "write": true
        },
        {
            "langauge": "Telugu",
            "speak": true,
            "read": false,
            "write": false
        },
        {
            "langauge": "Tamil",
            "speak": true,
            "read": true,
            "write": true
        },
        {
            "langauge": "Hindi",
            "speak": true,
            "read": true,
            "write": false
        }
    ]
}

function getlangaugesJSON() {
    return langaugesJSON;
}

export default getlangaugesJSON;

