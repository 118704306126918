import React, { Component } from 'react';
import BulletedTree from '../../Controls/BulletedTree';
import RadioButtons from '../../Controls/RadioButtons';

import Projects from './Projects';
import getSkillMatrix from '../Resume/data/SkillMatrixJSON';
import getProgrammingServiceOfferingsJSON from './data/ProgrammingOfferings';

import '../AppBodyCommon.css';
import '../Services.css';
import './ProgrammingService.css';

class ProgrammingService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRBIndex: 0
    };
  }

  rbJson = [
    {
      "label": "Programming Service Offerings",
      "text": "Custom software development tailored to your unique business needs."
    },
    {
      "label": "Technologies",
      "text": "Expertise in cutting-edge technologies to drive innovation and efficiency."
    },
    {
      "label": "Projects",
      "text": "Explore a few of my sample projects on Github."
    }
  ];

  techMain = ["Technologies"];
  techMainBelow = ["AI/ML/NLP/Gen AI", "UI Development", "APIs", "Databases", "Data Management", "Code Tools", "Tools"];
  techMainSide = ["Operating Systems", "Programming Languages", "Tech Stacks", "Cloud Service Providers"];
  titleStyle = {
    fontSize: '16px',
    //boxShadow: '2px 2px 2px 0px rgba(33, 150, 243, 0.8)',
    FontFace: 'Arial',
    padding: '2px 2px 2px 5px',
    boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
    borderRadius: '4px',
    fontVariant: 'small-caps',
    backgroundColor: 'rgba(250, 250, 212, 0.7)',
      paddingRight: '6px',
  paddingBottom: '4px'
}

subTitleStyle = {
  fontSize: '15px',
  FontFace: 'Arial',
  fontVariant: 'small-caps',
  padding: '2px 2px 2px 5px',
  boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
  borderRadius: '4px',
  marginTop:'8px',
  backgroundColor: 'rgba(250, 250, 60, 1)',
  width: 'fit-content',
    paddingRight: '6px',
  paddingBottom: '4px'
}

  subListStyle = { fontSize: '15px', paddingTop: '2px', paddingLeft: '2px' };

  subListStyle2 = { paddingTop: '2px', paddingLeft: '2px' };

  listStyle = { border: '1px solid black', padding: '8px', borderRadius: '4px', backgroundColor: 'rgba(250, 250, 210, .5)', marginLeft: '10px', height: 'fit-content'};

  handleRadioButtonChange = (index) => {
    console.log("in handleRadioButtonChange " + index);

    this.setState({ selectedRBIndex: index });
  }

  render() {
    return (
      <div className='app-tab-item-container'>
        <div style={{width: '100%'}}>
          <RadioButtons data={this.rbJson} OnStateChange={this.handleRadioButtonChange} selectedIndex='0' />
        </div>

        <div className='app-tab-item-container-items double-extra-top'>
          <div className={`${this.state.selectedRBIndex === 0 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <BulletedTree title="" data={getProgrammingServiceOfferingsJSON().data} titleStyle={this.titleStyle} subTitleStyle={this.subTitleStyle} subListStyle={this.subListStyle} showParentBullet={false} />
          </div>
          <div className={`${this.state.selectedRBIndex === 1 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '-24px' }}>
                <BulletedTree title="" subListStyle={this.subListStyle2} showParentBullet={false} data={getSkillMatrix().data} filter={this.techMain} />
                <BulletedTree title="" subListStyle={this.subListStyle2} showParentBullet={false} data={getSkillMatrix().data} filter={this.techMainSide} listStyle={this.listStyle} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                <BulletedTree title="" subListStyle={this.subListStyle2} showParentBullet={false} data={getSkillMatrix().data} filter={this.techMainBelow} />
              </div>
            </div>
          </div>
          <div className={`${this.state.selectedRBIndex === 2 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <Projects />
          </div>
        </div>
      </div>
    )
  }
};

export default ProgrammingService;

