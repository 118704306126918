import React from 'react';

import './VerticalTabs.css'; // Import your CSS file for styling

class VerticalTabs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.activeTab || 'tab0',
            activeTabIndex: 0
        };
    }

    openTab = (tabIndex) => {
        this.setState({ activeTab: 'tab' + tabIndex });
        this.props.OnTabClick(tabIndex);
    }

    getTabStyle = (index) => {
        let tabStyle = {
            borderTopRightRadius: index === 0 ? '8px' : this.state.activeTab === `tab${index}` ? '4px' : '0px',
            borderBottomRightRadius: index === this.props.data.length - 1 ? '8px' : this.state.activeTab === `tab${index}` ? '4px' : '0px'
        }
        return tabStyle;
    }

    render() {
        return (
            <div className="vert-tabs-container">
                {this.props.data.map((tabJson, index) => (
                    <div key={index} id={`tab${index}`} style={this.getTabStyle(index)}
                        className={`vert-tab ${this.state.activeTab === `tab${index}` ? 'vert-tab-active active-shadow' : ''}`} onClick={() => this.openTab(index)}>
                        <span>{tabJson.tabTitle}</span>
                        <i className={`${tabJson.iconClass !== null ? `rotated-tab-icon ${tabJson.iconClass}` : null}`}></i>
                    </div>
                ))}
            </div>
        )
    }
}

export default VerticalTabs;