import React, { Component } from 'react';

import ScrollToTop from '../Controls/ScrollToTop';
import AppBodyInnerHeader from './AppBodyInnerHeader';
import HorizontalTabs from '../Controls/HorizontalTabs';

import ArchitectureService from './Services/ArchitectureService';
import ProgrammingService from './Services/ProgrammingService';
import TechContentCreationService from './Services/TechContentCreationService';

import './AppBodyCommon.css';
import './Services.css';

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.getActiveTab() === 'none' ? 'tab0' : this.getActiveTab(),
      text: 'This is tab0'
    };
  }

  jsonData = [
    {
      tabTitle: 'Architect',
    },
    {
      tabTitle: 'Programmer',
    },
    {
      tabTitle: 'DevOps',
    },
    {
      tabTitle: 'Content Maker',
    }
  ];

  setURLPath = (tab) => {
    var url = window.location.origin + "/" + this.getURLPath(tab);

    window.history.pushState({}, '', url);
  }

  getURLPath = (tab) => {
    if (tab === 'tab0') {
      return 'services/architect';
    }
    else if (tab === 'tab1') {
      return 'services/programmer';
    }
    else if (tab === 'tab2') {
      return 'services/content-maker';
    }

    return '';
  }

  componentDidMount() {
    var tabActive = this.getActiveTab() === 'none' ? 'tab0' : this.getActiveTab()

    this.setState({ activeTab: tabActive});
    //this.setURLPath(this.getActiveTab());
  }

  getActiveTab = () => {
    var service = this.props.activeService || 'none';
    service = service.toLowerCase();

    if (service === 'architect') {
      return 'tab0';
    } else if (service === 'programmer') {
      return 'tab1';
    }
    else if (service === 'content-maker') {
      return 'tab2';
    }

    return 'none';
  }

  handleTabChange = (tabId) => {
    this.setState({ activeTab: `tab${tabId}` });
    this.setState({ text: `This is ${tabId}` });

    //this.setURLPath(`tab${tabId}`);
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <AppBodyInnerHeader title="MY SERVICES" summary="Welcome to my digital hub! I'm here to bring your projects to life with my expertise in architectural design and programming finesse. I also create captivating technical content. Explore the different <span style='color: blue; font-style: oblique; font-weight: 500'>freelance services</span> I offer by clicking on the below tabs."
          controlsComponent={HorizontalTabs} activeTab={this.state.activeTab} data={this.jsonData} OnStateChange={this.handleTabChange} />

        <div className='app-tab-items' >
          <div className={`${this.state.activeTab === "tab0" ? 'app-tab-item visible' : 'app-tab-item invisible'}`} >
            <ArchitectureService />
          </div>

          <div className={`${this.state.activeTab === "tab1" ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <ProgrammingService />
          </div>

          <div className={`${this.state.activeTab === "tab2" ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <TechContentCreationService />
          </div>
        </div>

        <ScrollToTop />
      </div>
    )
  }
}

export default Services;