import React, { Component } from 'react';

import AppHeader from '../AppHeader/AppHeader';
import AppBody from '../AppBody/AppBody';

import './AppArchServices.css';
import './AppMain.css';

class AppArchServices extends Component {

  render() {
    return (
      <div className="AppMain">
        <AppHeader />
        <AppBody activeTab='tab1' activeService="architect"/>
      </div>
    );
  }
}

export default AppArchServices;