const ContentCreatonOfferingsJSON = {
    "data": [
        {
            "title": "Content Creation Services",
            "list": [
                "Develop engaging slide decks for technical topics",
                "Create visually appealing traiing material for technical presentations.",
                "Create comprehensive technical documentation including architecture and other technical diagrams.",
                "Develop interactive training modules and learning resources.",
                "Create tutorials and walkthroughs for complex technical topics.",
                "Develop walkthrough Videos for technical topics.",
                "Share practical tips and best practices for developers and engineers.",
                "Write insightful blog posts covering emerging trends and technologies.",
                "Ensure consistency and adherence to established style guidelines."
            ]
        },
        {
            "title": "Creating Digital Learning Library/Resources",
            "children": [
                {
                    "title": "Software Guided Labs Creation",
                    "list": [
                        "Customized Lab Design: Tailored lab environments designed to meet specific learning objectives and technical requirements.",
                        "Content Development: Creation of engaging and informative lab content, including tutorials, exercises, and assessments.",
                        "Lab Environment Setup: Provisioning of virtual or physical lab environments with necessary software, tools, and configurations.",
                        "Guided Learning Paths: Development of structured learning paths to guide users through the labs, ensuring a progressive and comprehensive learning experience.",
                        "Interactive Features: Integration of interactive elements such as quizzes, challenges, and simulations to enhance learner engagement and retention.",
                        "Monitoring and Support: Monitoring of lab usage and performance, along with ongoing support and troubleshooting assistance for users.",
                        "Scalability and Flexibility: Scalable solutions that can accommodate varying numbers of users and adapt to changing requirements.",
                        "Integration Services: Integration with learning management systems (LMS), virtual learning environments (VLE), or other educational platforms for seamless deployment and management.",
                        "Reporting and Analytics: Generation of reports and analytics on user engagement, progress, and performance to track learning outcomes and identify areas for improvement."
                    ]
                },
                {
                    "title": "Online Courses",
                    "list": [
                        "Design comprehensive online courses covering various technical subjects.",
                        "Create interactive modules with quizzes and assignments for hands-on learning."
                    ]
                },
                {
                    "title": "Code Snippets",
                    "list": [
                        "Craft informative and educational sample code snippets.",
                        "Develop interactive coding exercises to reinforce learning and enhance hands-on experience.",
                        "develop and share entire projects and applications for learning and reference.",
                        "Design coding challenges with varying levels of difficulty to cater to different skill levels.",
                    ]
                },
                {
                    "title": "Video Tutorials",
                    "list": [
                        "Produce engaging video tutorials for software development tools and frameworks.",
                        "Demonstrate step-by-step solutions to common technical challenges."
                    ]
                },
                {
                    "title": "Assessments & Quizzes",
                    "list": [
                        "Design quizzes to test understanding of technical concepts.",
                        "Create assessments to evaluate proficiency in programming languages."
                    ]
                },
                {
                    "title": "Case Studies",
                    "list": [
                        "Write compelling case studies showcasing successful technical implementations.",
                        "Analyze and document real-world use cases for technical products."
                    ]
                },
                {
                    "title": "Evaluations",
                    "list": [
                        "Design and develop student evaluations for technical courses.",
                        "Create assessments to evaluate proficiency in programming languages."
                    ]

                }
            ]
        },
        {
            "title": "Technical Writing Services",
            "children": [
                {
                    "title": "Technical Reviews",
                    "list": [
                        "Conduct thorough reviews of technical content for accuracy and clarity.",
                        "Provide constructive feedback to improve technical documentation.",
                        "Offer proofreading services for technical documents and manuscripts.",
                        "Ensure accuracy and consistency in spelling, punctuation, and grammar."
                    ]
                },
                {
                    "title": "Documentation Templates",
                    "list": [
                        "Design customizable templates for technical documentation.",
                        "Provide templates for software architecture diagrams and project plans."
                    ]
                }
            ]
        },
    ]
}

function getContentCreatonOfferingsJSON() {
    return ContentCreatonOfferingsJSON;
}

export default getContentCreatonOfferingsJSON;
