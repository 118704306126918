import React, { Component } from 'react';

import getProjectsJSON from './data/ProjectsJSON';

import '../AppBodyCommon.css';
import '../FreeContent.css';
import './Projects.css';

class Projects extends Component {

    node = getProjectsJSON().data;

    render() {
        return (
            <div className='app-tab-item-child-container'>
                {this.node.map((project, index) => (
                    <div key={index} className='projects-bulleted-link-tree'>
                        <div className="project-title">
                            <span className='projects-bulleted-link-tree-node-title'>Project: </span>
                            <span className='projects-bulleted-link-tree-node-text'>{project.Project}</span>
                        </div>

                        <div>
                            <span className='projects-bulleted-link-tree-node-title'>Description: </span>
                            <span className='projects-bulleted-link-tree-node-text'>{project.description}</span>
                        </div>
                        <div className='projects-bulleted-link-tree-node-title'>Features</div>
                        {project.Features && project.Features.length > 0 && (
                            <ul className='projects-bulleted-link-list'>
                                {project.Features.map((feature, indexFeature) => (
                                    <li key={indexFeature} className='projects-bulleted-link-tree-list-item-text'>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        )}

                        {project.SourceCode && project.SourceCode.length > 0 && (
                            <div style={{ width: '100%', marginBottom:'30px' }}>
                                <div className='projects-bulleted-link-tree-node-title'>Source Code</div>
                                                    <ul className='projects-bulleted-link-list'>
                                                        {project.SourceCode.map((source, indexSource) => (
                                                            <li key={indexSource} className='projects-bulleted-link-tree-list-item-text'>
                                                                <strong>{source.ProgrammingLanguage}: </strong>
                                                                <a href={source.Link} target='_blank' rel='noopener noreferrer'>{source.Link}</a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
        )
    }
}

export default Projects;

