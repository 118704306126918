import React, { Component } from 'react';
import './AppMain.css';

import AppHeader from '../AppHeader/AppHeader';
import AppBody from '../AppBody/AppBody';

class AppContentMakerService extends Component {

  render() {
    return (
      <div className="AppMain">
        <AppHeader />
        <AppBody activeTab='tab3'/>
      </div>
    );
  }
}

export default AppContentMakerService;