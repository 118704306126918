import React from 'react';
import ImageGallery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';

import './SampleDiagrams.css';

import getTechDiagramsJSON from './data/techDiagrams';

class SampleDiagramSet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: []
        }
    }

    componentDidMount() {
        let images = [];

        this.props.data.diagrams.forEach((diagram, index) => {
            images.push({
                original: diagram.link,
                thumbnail: diagram.link,
                description: diagram.description,
                diagram_type: diagram.diagram_type,
                title: diagram.diagram_type
            });
        });

        this.setState({ images: images });
    }

    render() {
        return (
            <div className='sample-diagram-set-container'>
                <ImageGallery items={this.state.images} renderItem={this.renderItem} autoPlay={this.props.autoPlay} />
                <hr></hr>
            </div>
        )
    }

    renderItem(item) {
        return (
            <div className='image-gallery-image'>
                <img
                    src={item.original}
                    alt={item.description}
                    srcSet={item.srcSet}
                    sizes={item.sizes}
                    title={item.title}
                />
            </div>
        );
    }
}

class SampleDiagrams extends React.Component {

    techDiagramsJSON = getTechDiagramsJSON();

    render() {
        return (
            <div className='app-tab-item-child-container'>
                {this.techDiagramsJSON.data.map((diagramSetJSON, index) => (
                    <div key={index}>
                        <div key={index} className="sample-diagrams-container">
                            <div className="sample-diagram-topic">{diagramSetJSON.topic}</div>
                            <div className="sample-diagram-description">{diagramSetJSON.description}</div>

                            <SampleDiagramSet data={diagramSetJSON} autoPlay={index === 0 ? true : false} />
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default SampleDiagrams;