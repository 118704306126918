import { Component } from "react";
import { Link } from "react-router-dom";
import { IN } from 'country-flag-icons/react/3x2'

import CurrentTime from "../Controls/CurrentTime";

import './AppHeader.css';

class AppHeader extends Component {
  render() {
    return (
      <div className="app-header">
        <div className="app-header-self">
          <div className="ring-container">
            <div className="ring"></div>
            <div className="ring"></div>
            <div className="ring"></div>
            <img src="/images/umesh-works-logo.jpg" alt=""/>
          </div>
          <div className="my-self-name-title">
            <div className="my-name"><Link to="/" style={{ color: 'blue' }}>Ramesh Viswanathan</Link></div>
            <div className="my-title">Freelancer</div>
          </div>
        </div>

        <div className="app-header-contact">
          <div className="my-header-contact-text" style={{ paddingBottom: '2px' }}  >
            <div className="my-contact-email-icon">
              <img src='/images/email.jpg' alt=''></img>
              <span style={{verticalAlign: 'middle'}}><a href="mailto:contact@rameshv.me" style={{ paddingLeft: '4px' }}>contact@rameshv.me</a></span>
            </div>
          </div>

          <div className="my-header-contact-text" style={{ paddingBottom: '2px' }}>
            <div className="my-contact-icon">
              <img src='/images/mobile.jpg' alt=''></img>
              <img src='/images/whatsapp.png' alt=''></img>
              <span style={{ paddingLeft: '2px', verticalAlign: 'middle' }}>+91 96006 39899</span>
            </div>
          </div>

          <div className="my-country-content">
            <div className="my-flag"><IN title="India" className="country-flag" ></IN></div>
            <div className="my-country">India -&nbsp;<CurrentTime culture="en-IN" timeZone="Asia/Kolkata" /> </div>
          </div>
          <div className='my-header-contact-text'><strong>GSTIN:</strong> 33ADWPR9079B1ZY</div>
        </div>
      </div >
    );
  }
}

export default AppHeader;