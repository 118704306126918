const skillMatrix = {
  "data": [
    {
      "title": "Roles",
      "category": "Roles",
      "description": "Roles I have played in my career",
      "list": [
        "Independent Consultant/Freelancer",
        "Programmer",
        "Software Engineer",
        "CTO",
        "Chief Architect",
        "VP of Engineering",
        "Director of Engineering",
        "Associate Director",
        "Tech Lead",
        "Process and Project Manager",
        "Delivery Manager",
        "Senior Developmnent Manager",
        "Senior Architect",
        "Solution Architect",
        "-",
        "Client Relationships Manager",
        "Customer Success Manager",
        "Proposal Developer",
        "Production Support Engineer",
        "Business Developer",
        "Product Head",
        "-",
        "QA Lead",
        "UX/UI Lead",
        "DevOps Lead",
        "Data Science Lead",
        "BI & Analytics Lead",
        "-",
        "Auditor",
        "Security & Risk Manager",
        "-",
        "Trainer",
        "Mentor",
        "Coach",
        "Innovator"
      ],
    },
    {
      "title": "Management",
      "category": "Roles",
      "description": "Roles I have played in my career",
      "list": [
        "Project Management",
        "Delivery Management",
        "Client Relationships Management",
        "Client Interactions",
        "Process Management",
        "People Manaeement",
        "Vendor Management",
        "Solution Delivery Management",
        "-",
        "Business Development",
        "Product Strategy",
        "Roadmap and Development",
        "Market & Competitor Analysis",
        "-",
        "Upskilling Teams",
        "Mentoring",
        "Performance Management",
        "Resource Management",
        "-",
        "Budgeting",
        "Cost Control",
        "Risk Management",
        "Quality Management",
        "Change Management",
        "Estimation & Costing",
        "Production Support",
      ],
    },

    {
      "title": "Domains",
      "category": "Domains",
      "description": "Domains I have worked in",
      "list": [
        "eCommerce",
        "Supply Chain Management",
        "AgriTech",
        "EdTech",
        "Expert Services",
        "Digital Marketing",
        "Intelligent Process Automation",
        "RPA",
        "Medical Sales Reprorting",
        "Mortgage",
        "Loan Origination",
        "Data Automation",
        "F&A",
        "Investment Brokerage",
        "Stock Exchange Sales Trading",
        "Healthcare",
        "Patient Health Records",
        "Video & Image Processing",
        "Human Resource Management",
        "CRM",
        "CMS",
        "ERP"
      ]
    },

    {
      "title": "Architectures",
      "category": "Architectures & Technical Design",
      "description": "Architectures & Techniccal Designs I have worked with",
      "list": [
        "Microservices",
        "Serverless",
        "Cloud Native",
        "-",
        "Monolithic",
        "Client-Server",
        "N-tier",
        "Layered",
        "Distributed",
        "Parallel Computing",
        "-",
        "SOA",
        "Web Services",
        "RESTful Services",
        "GraphQL",
        "SOAP",
        "MVC",
        "MVVM",
        "-",
        "Workflow",
        "Saga",
        "CQRS",
        "Frameworks",
        "-",
        "Service Bus",
        "Message Queue",
        "Message Broker",
        "Event Bus",
        "Event Driven",
        "Pub/Sub",
        "Request/Response",
        "Push/Pull",
      ],
    },
    {
      "title": "Tech Designs",
      "category": "Architectures & Technical Design",
      "description": "Architectures & Techniccal Designs I have worked with",
      "list": [
        "Software Paradigms",
        "Object Oriented",
        "Functional",
        "Imperative",
        "Procedural",
        "Declarative",
        "-",
        "Aspect Oriented",
        "Async Programming",
        "Parallel Programming",
        "Concurrent Programming",
        "Reactive Programming",
        "Event Driven Programming",
        "-",
        "Component Based",
        "Service Oriented",
        "SOLID",
        "GoF Design Patterns",
        "Dependency Injection",
        "Inversion of Control",
        "CQRS"
      ],
    },

    {
      "title": "Operating Systems",
      "category": "Technologies",
      "description": "Tech Stacks I have worked with",
      "list": [
        "Windows NT",
        "Windows Server 2019",
        "Windows 10",
        "Linux",
        "Ubuntu",
        "RedHat",
        "CentOS",
        "Tandem",
        "Unix"
      ],
    },
    {
      "title": "Tech Stacks",
      "category": "Technologies",
      "description": "Tech Stacks I have worked with",
      "list": [
        ".NET",
        "Java",
        "NodeJS",
        "JS Frameworks - React, Vue, Angular",
        "Python",
        "PHP",
        "C++/VC++",
      ]
    },
    {
      "title": "Programming Languages",
      "category": "Technologies",
      "description": "Tech Stacks I have worked with",
      "list": [
        "C",
        "C++/VC++",
        "C#",
        "VB.NET",
        "Java",
        "Javascript",
        "Typescript",
        "Python",
        "Perl",
        "PHP",
        "-",

        "Borland C++ Builder",
        "Visual Basic",
        "ASP",
        "CGI",
        "XML/XSD/XSL/XSLT/XPath",
        "-",
        "SQL",
        "PL/SQL",
        "T-SQL",
        "-",
        "HTML",
        "DHTML",
        "CSS",
        "-",
        "Bash",
        "Shell",
        "PowerShell script"
      ],
    },
    {
      "title": "Technologies",
      "category": "Technologies",
      "description": "Tech Stacks I have worked with",
      "list": [
        ".NET Framework 6.0",
        ".NET Core 3.1",
        "WebForms",
        "WPF",
        "ASP.NET Core",
        "ASP.NET MVC",
        "ASP.NET WebAPI",
        "ASP.NET Razor Pages",
        "ASP.NET Razor Views",
        "-",

        "J2EE",
        "Spring Boot",
        "Spring MVC",
        "Spring Cloud",
        "Hibernate",
        "Struts",
        "JSP",
        "JMI",
        "JMS",
        "Servlets",
        "ODBC/JDBC",
        "Maven",
        "Gradle",
        "-",

        "ADO.NET",
        "Entity Framework",
        "LINQ",
        "-",

        "NodeJS",
        "Yarn",
        "Express",
        "Electron",
        "React",
        "Vue ",
        "Angular",
        "Nuxt",
        "Next",
        "Redux",
        "Vuex",
        "NgRx",
        "RxJS",
        "Bootstrap",
        "Material",
        "Tailwind",
        "Vuetify",
        "Quasar",
        "Fluent",
        "PrimeNG",

        "Xamarin",
        "Flutter ",
        "PWA",
        "Blazor",
        "React Native",
        "Ionic",
        "Cordova",
        "Google Firebase",
        "-",

        "Jupyter Notebook",
        "Spyder",
        "VS Code",
        "Django",
        "Flask",
        "NumPy",
        "Pandas",
        "Matplotlib",
        "TensorFlow",
        "Git",
        "-",

        "Kafka",
        "RabbitMQ",
        "Azure Event Hub",
        "Azure Service Bus",
        "AWS SQS",
        "Google Cloud Pub/Sub",
        "IBM MQ Series",
        "-",

        "WCF",
        "COM/COM+",
        "MFC",
        "ATL",
        "Win32",
        "CGI",
        "HTTP",
        "SSL",
        "ASF",
        "NetShow",
        "-",

        "ActiveX",
        "Web Services",
        "SOAP",
        "Mozilla",
        "VBA",
        "Boost",
        "ACE",
        "Apache Tomcat",
        "SOLR",
        "-",

        "Lucene",
        "JBoss",
        "WebLogic",
        "WebSphere",
        "-",

        "Consul",
        "Zookeeper",
        "Eureka",
        "Hystrix",
        "-",

        "Google Analytics",
        "SEO"
      ],
    },
    {
      "title": "UX/UI",
      "category": "Frontend Development",
      "description": "Frontend Development I have worked with",
      "list": [
        "Wireframes",
        "Prototypes",
        "Mockups",
        "Visual Design",
        "Interaction Design",
        "Information Architecture",
        "-",

        "User Stories",
        "User Journeys",
        "User Flows",
        "User Personas",
        "User Experience",
        "User Interface",
        "User Empathy",
        "User Onboarding",
        "User Training",
        "User Support",
        "User Documentation",
        "User Help"
      ]
    },
    {
      "title": "UI Development",
      "category": "Frontend Development",
      "description": "Tech Stacks I have worked with",
      "list": [
        "HTML",
        "DHTML",
        "CSS",
        "Bootstrap",
        "Material",
        "Tailwind",
        "UI Libraries",
        "-",
        "Web Frameworks",
        "React",
        "Vue",
        "Angular",
        "Javascript",
        "Typescript",
        "JQuery",
        "-",
        "NodeJS",
        "Yarn",
        "Express",
        "Electron",
        "-",
        "Xamarin",
        "Flutter",
        "Cordova",
        "Google Firebase",
        "WinForms",
        "WPF",
        "Silverlight",
        "ASP.NET",
        "ASP.NET MVC",
        "ASP.NET Core",
        "ASP.NET Razor Pages",
        "ASP.NET Razor Views"
      ]
    },
    {
      "title": "APIs",
      "category": "Technologies",
      "description": "Tech Stacks I have worked with",
      "list": [
        "API Gateway",
        "API Management",
        "API Security",
        "API Documentation",
        "API Swagger",
        "API Testing",
        "API Monitoring",
        "API Analytics",
        "API Governance",
        "-",
        "API Lifecycle",
        "API Design",
        "API Development",
        "API Deployment",
        "API Consumption",
        "API Integration",
        "API Orchestration",
        "API Transformation",
        "API GraphQL",
      ],
    },
    {
      "title": "Databases",
      "category": "Technologies",
      "description": "Tech Stacks I have worked with",
      "list": [
        "SQL Server",
        "MySQL",
        "Oracle",
        "Sybase",
        "DB2",
        "PostgreSQL",
        "MS Access",
        "Non-Stop SQL",
        "-",

        "Hadoop",
        "Hive",
        "MongoDB",
        "Cosmos DB",
        "Cassandra",
        "-",

        "Redis Cache",
        "Memcached",
        "Pinecone",
        "-",

        "XML Databases",
        "In-Memory Database",
        "AppFabric",
        "Oracle Coherence",
        "SQL Azure",
        "SQL Lite",
        "SQL Anywhere",
        "SQL Express",
        "-",

        "Azure Data Lake",
        "Azure Data Warehouse",
        "Dremio",
        "Snowflake",
        "Neo4j",
        "-",

        "Amazon Redshift",
        "Amazon RDS",
        "Amazon Aurora",
        "Amazon DynamoDB",
        "Google Cloud SQL",
        "BigQuery"
      ],
    },
    {
      "title": "Data Management",
      "category": "Technologies",
      "description": "Tech Stacks I have worked with",
      "list": [
        "Database Design",
        "Tabular, Key-Value, Document, Columnar, Graph, Time Series, Object, XML, JSON, Binary, Spatial, Temporal, In-Memory, Cloud, Distributed",
        "Data Model",
        "Data Flow",
        "Data Integration",
        "Data Transformation",
        "Data Migration",
        "Data Replication",
        "-",
        "Data Warehousing",
        "Data Mining",
        "Data Lake",
        "Data Analytics",
        "Data Visualization",
        "Data Reporting",
        "-",
        "Data Governance",
        "Data Security",
        "Data Privacy",
        "Data Quality",
        "Data Catalog",
        "Data Masking",
        "-",
        "Data Archiving",
        "Data Backup",
        "Data Recovery",
        "Data Retention",
        "Data Storage",
        "Data Compression",
        "-",
        "Data Encryption",
        "Data Decryption",
        "-",
        "Data Indexing",
        "Data Partitioning",
        "Data Sharding",
      ]
    },
    {
      "title": "Cloud Service Providers",
      "category": "Technologies",
      "description": "Tech Stacks I have worked with",
      "list": [
        "Azure",
        "AWS",
        "Google Cloud ",
        "Hetzer "
      ],
    },
    {
      "title": "AI/ML/NLP/Gen AI",
      "category": "Data Science & AI",
      "description": "Data Science & AI Stacks I have worked with",
      "list": [
        "Regression",
        "Classification",
        "Clustering",
        "Neural Networks",
        "Deep Learning",
        "Machine Learning",
        "Computer Vision",
        "Translation",
        "Video Analysis",
        "Recommendations",
        "Conversational AI",
        "ChatBOTs",
        "Sentiment Analysis",
        "House Number Recognition",
        "Captcha Extraction",
        "Predictive Analysis",
        "Intelligent Assessments",
        "Adaptive Learning",
        "Data Analytics",
        "Object Detection",
        "Image Classification",
        "YOLO",
        "-",

        "CNN",
        "NLP",
        "Standford NLP Parser",
        "OpenNLP",
        "Spacy",
        "NLTK",
        "Text Classification",
        "Text Summarization",
        "Text Generation",
        "Data Extraction",
        "Data Augmentation",
        "NER",
        "POS Tagger",
        "Segmentation",
        "Translation",
        "Tokenization",
        "-",

        "Vectorization",
        "Embedding",
        "Vectorization",
        "RAG",
        "-",

        "Open AI ChatGPT",
        "Prompt Engineering",
        "Zero Shot Prompting",
        "Few Shot Prompting",
        "Chain of Thought Prompting",
        "Self Consistency Prompting",
        "General Knowledge Prompting",
        "ChatGPT Functions",
        "ChatGPT Conversations",
        "ChatGPT Fine-Tuning",
        "-",

        "GPT Engineeer",
        "Auto GPT",
        "LangChain",
        "Hugging Face",
      ],
    },

    {
      "title": "Software Engineering",
      "category": "Software Engineering",
      "description": "Software Engineering methodologies and tools I have used",
      "list": [
        "Agile SCRUM",
        "Agile Kanban",
        "Test Driven Development (TDD)",
        "Extreme Programming (XP)",
        "Waterfall",
        "Iterative",
        "SDLC",
        "Jira",
        "Azure Devops",
      ],
    },

    {
      "title": "Code Tools",
      "category": "Code",
      "description": "Code Tools I have worked with",
      "list": [
        "TFS",
        "SVN",
        "GIT",
        "BitBucket",
        "Jenkins",
        "-",
        "SonarQube",
        "NUnit",
        "MSTest",
        "Junit",
        "JMeter",
        "LoadRunner",
        "Selenium",
        "Cucumber",
        "-",
        "Postman",
        "Fiddler",
        "Visual Studio",
        "Eclipse",
        "IntelliJ",
        "XCode",
        "Android Studio",
        "Visual Studio Code",
        "Sublime",
        "Notepad++",
        "Vim",
        "Emacs",
      ],
    },
    {
      "title": "Code Quality",
      "category": "Code",
      "list": [
        "Code Reviews",
        "Static Code Analysis",
        "Code Metrics",
        "Cyclomatic Complexity",
        "Depth of Inheritance",
        "Code Coverage",
        "Code Profiling",
        "Code Refactoring",
        "Code Optimization",
      ]
    },

    {
      "title": "Tools",
      "category": "Tools",
      "description": "Tools I have worked with",
      "list": [
        "MS Office Suite",
        "Office 365",
        "Google Suite",
        "Jira",
        "Azure Devops",
        "Visual Paradigm",
        "Rational Rose",
        "Visio",
        "SmartDraw",
        "LucidChart",
        "Draw.io",
        "-",

        ".NET SDK",
        "NuGet Package Manager",
        "MSBuild",
        "Entity Framework Core",
        "LINQPad",
        "Resharper",
        "Postman",
        "Fiddler",
        "NUnit",
        "MSTest",
        "-",

        "Junit",
        "Tomcat",
        "Nexus Repository",
        "Maven",
        "Gradle",
        "-",

        "NPM",
        "NVM",
        "Yarn",
        "Webpack",
        "Grunt",
        "Gulp",
        "-",

        "PyCharm",
        "Jupyter Notebook",
        "Spyder",
        "Django",
        "Flask",
        "NumPy",
        "Pandas",
        "Matplotlib",
        "TensorFlow",
        "Jupyter Notebook",
        "-",

        "Jenkins",
        "Docker",
        "Kubernetes",
        "Ansible",
        "Terraform",
        "-",

        "Git",
        "GitHub",
        "GitLab",
        "BitBucket",
        "TFS",
        "SVN",
        "CVS",
        "Perforce",
        "VSS",
        "-",

        "Visual Studio Code",
        "Visual Studio",
        "Eclipse",
        "IntelliJ",
        "XCode",
        "Android Studio",
        "Sublime",
        "Notepad++",
        "Vim",
        "Emacs",
        "-",

        "ServiceNow",
        "PagerDuty",
        "OpsGenie",
        "Slack",
        "Microsoft Teams",
        "Zoom",
        "Confluence",
        "Google Workspace",
        "-",

        "Prometheus",
        "Grafana",
        "ElasitcSearch",
        "Kibana",
        "Logstash",
        "Splunk",
        "New Relic",
        "Azure Monitor",
        "Azure Application Insights",
        "Azure Log Analytics",
        "AWS CloudWatch",
        "Google Cloud Monitoring",
        "-",

        "JMeter",
        "LoadRunner",
        "-",

        "Jira",
        "Azure Devops",
        "Trello",
        "MS Project"

      ],
    },
    {
      "title": "Design and Documentation Tools",
      "category": "Tools",
      "description": "Tools I have worked with",
      "list": [
        "UML",
        "MS Visio",
        "Visual Paradigm",
        "Rational Rose",
        "RUP",
        "Office 365",
        "Google Suite",
        "Confluence",
        "Wiki",
      ]
    },

    {
      "title": "Soft Skills",
      "category": "Other",
      "description": "Other things I have done in my career",
      "list": [
        "Continous Learner",
        "Quick Learner",
        "Excellent Communication Skills",
        "Excellent Documentation Skills",
        "Collaborative development",
        "Team player",
        "Mentor",
        "-",
        "Leader",
        "Innovator",
        "Problem Solver",
        "Decision Maker",
        "Motivator",
        "Good Listener",
        "Trainer",
      ]
    },
    {
      "title": "Work Locations",
      "category": "Other",
      "description": "Other things I have done in my career",
      "list": [
        "India",
        "US",
        "Singapore",
        "UK"
      ],
    }
  ]
};

function getSkillMatrix() {
  return skillMatrix;
}

export default getSkillMatrix; 
