import React, { Component } from 'react';
import './CheckBoxes.css';

class CheckBoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: this.props.selectAll,
      CbLabels: this.props.data,
      selected: this.props.initialSelected
    };
  }

  toggleAll = () => {
    this.setState({ selectAll: !this.state.selectAll });
    const selectedVals = [...this.state.selected];

    //update the selected array with value 1 or 0
    selectedVals.fill(!this.state.selectAll);

    this.setState({ selected: selectedVals });
    this.props.onStateChange(selectedVals);
  }

  toggleCheckBoxState = (index) => {
    console.log("in toggleCheckBoxState index " + index);

    var selectedVals = [...this.state.selected]; // Create a copy of the selected array
    selectedVals[index] = !selectedVals[index]; // Modify the value at the given index
    console.log("in toggleCheckBoxState selVals " + selectedVals);

    this.setState({ selected: selectedVals }); // Update the state with the new array

    var allSelected = true;
    selectedVals.forEach(element => {
      if (element === false) {
        allSelected = false;
      }
    });

    this.setState({ selectAll: allSelected });

    console.log("in toggleCheckBoxState " + this.state.selected);
    this.props.onStateChange(selectedVals);
  }


  render() {
    return (
      <div className="checkboxes-container">
        <label className="checkbox-container">
          <input id="cbAll" type='checkbox' onChange={this.toggleAll} checked={this.state.selectAll} />
          <span className="checkmark" style={{ backgroundColor: '#FFFF00' }}></span>
          <span className="checkbox-label">All</span>
        </label>
        
        {this.state.selected.map((sel, index) => (
          <label className="checkbox-container" key={index}>
            <input
              id={`cb${this.state.CbLabels[index].replaceAll(" ", "")}`}
              type='checkbox'
              onChange={() => this.toggleCheckBoxState(index)}
              checked={sel}
            />
            <span className="checkmark"></span>
            <span className="checkbox-label">{this.state.CbLabels[index]}</span>
          </label>
        ))}
      </div>
    );
  }
}

export default CheckBoxes;