import React, { Component } from 'react';

import VerticalTabs from '../Controls/VerticalTabs';

import Home from './Home';
import Resume from './Resume';
import Services from './Services';
import FreeContent from './FreeContent';

import './AppBody.css';

class AppBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            activeTab: this.props.activeTab || 'tab0',
            activeTabIndex: 0,
            scrollPositions: [0, 0, 0]
        };
    }

    jsonData = [
        {
            tabTitle: 'HOME',
            iconClass: 'fa fa-home'
        },
        {
            tabTitle: 'CONSULTING',
            iconClass: 'fa fa-handshake'
        },
        {
            tabTitle: 'EXPERIENCE',
            iconClass: 'fa fa-briefcase'
        },
        {
            tabTitle: 'FREE CONTENT',
            iconClass: 'fa fa-gift'
        }
    ];

    setActiveTab = (tabIndex) => {
        console.log("setActiveTab");
        this.setState({ activeTabIndex: tabIndex });
        this.setState({ activeTab: 'tab' + tabIndex });
        this.setURLPath('tab' + tabIndex );

        window.scrollTo(0, 0);
    }


    componentDidMount() {
        console.log("++++++++++componentDidMount " + this.state.activeTab);
        window.scrollTo(0, 0);
        this.setURLPath(this.state.activeTab);
        this.setState({ isLoading: false });
    }

    setURLPath = (tab) => {
        var url = window.location.origin + "/" + this.getURLPath(tab);

        console.log("++++++++++setURLPath " + url); 
        
        window.history.pushState({}, '', url);
    }

    getURLPath = (tab) => {
        console.log("++++++++++getURLPath " + tab);
        if (tab === 'tab0') {
            return '';
        }
        else if (tab === 'tab1') {
            return 'services';
        }
        else if (tab === 'tab2') {
            return 'experience';
        }
        else if (tab === 'tab3') {
            return 'free-content';
        }
    }

    render() {
        return (
            this.state.isLoading ? (<div style={{ marginTop: '10px' }}>Loading...</div>) : (
            <div className='app-body'>
                <VerticalTabs data={this.jsonData} activeTab={this.state.activeTab} OnTabClick={this.setActiveTab} />

                <div className='app-body-content'>
                    <div className='app-body-tab home-tab-content' style={{ display: this.state.activeTab === 'tab0' ? 'block' : 'none' }}>
                        <Home />
                    </div>
                    <div className='app-body-tab' style={{ display: this.state.activeTab === 'tab1' ? 'block' : 'none' }}>
                        <Services activeService={this.props.activeService}/>
                    </div>
                    <div className='app-body-tab' style={{ display: this.state.activeTab === 'tab2' ? 'block' : 'none' }}>
                        <Resume />
                    </div>
                    <div className='app-body-tab'style={{ display: this.state.activeTab === 'tab3' ? 'block' : 'none' }}>
                        <FreeContent />
                    </div>
                </div>
            </div>
            )
        );
    }
}

export default AppBody;