import React, { Component } from 'react';

import AppHeader from '../AppHeader/AppHeader';
import ContentSubjects from '../AppBody/Services/ContentSubjects';

import './AppTopics.css';
import '../AppBody/AppBody.css';
import '../AppBody/AppBodyCommon.css';
import '../AppBody/Services.css';
import '../AppBody/Services/TechContentCreationService.css';


class AppTopics extends Component {
  render() {
    return (
      <div className="AppTopics">
        <AppHeader />
        <div className='app-body' style={{marginLeft: '-40px', marginTop: '-20px'}}>
          <div className='app-body-content' style={{width: 'calc(100% - 30px)'}}>
            <div className='app-body-tab'>
              <div className='app-tab-items'>
                <div className='app-tab-item visible'>
                  <div className='app-tab-item-container'>
                    <div className='app-tab-item-container-items extra-top'>
                      <div className='app-tab-item-container-item visible'>
                        <ContentSubjects />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppTopics;