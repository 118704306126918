import React from 'react';
import './HorizontalTabs.css'; // Import your CSS file for styling

class HorizontalTabs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.activeTab || 'tab0',
        };
    }

    openTab = (tabName) => {
        this.setState({ activeTab: 'tab' + tabName });
        this.props.OnStateChange(tabName);
    };

    getTabStyle = (index) => {
        let tabStyle = {
            borderBottomLeftRadius: index === 0 ? '4px' : this.state.activeTab === `tab${index}` ? '4px' : '0px',
            borderBottomRightRadius: index === this.props.data.length - 1 ? '4px' : this.state.activeTab === `tab${index}` ? '4px' : '0px',

        }
        return tabStyle;
    }

    render() {
        return (
            <div className="horz-tabs-container">

                {this.props.data.map((tabJson, index) => (
                    <div class="radio-input">
                        <input value={`color-${index}`} name="color" id={`color-${index}`} type="radio"  onClick={() => this.openTab(index)}/>
                        <label for={`color-${index}`}>
                            <span>
       
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 30"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <g id="Interface / Check"> <path stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#ffffff" d="M6 12L10.2426 16.2426L18.727 7.75732" id="Vector"></path> </g> </g></svg>
                            </span>&nbsp;
                            {tabJson.tabTitle}
                        </label>
                    </div>
                    /*                         <div key={index} id={`tab${index}`} className={`horz-tab ${this.state.activeTab === `tab${index}` ? 'horz-tab-active ' : 'horz-tab'}`} style={this.getTabStyle(index)} onClick={() => this.openTab(index)}>
                                                <span className={`${this.state.activeTab === `tab${index}` ? 'horz-tab-text active' : 'horz-tab-text'}`}>{tabJson.tabTitle}</span>
                                            </div> */
                ))}
            </div>
        )
    }
}

export default HorizontalTabs;