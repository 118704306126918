import React, { Component } from 'react';

import AppBodyInnerHeader from './AppBodyInnerHeader';

import './AppBodyInnerHeader.css';
import RadioButtons from '../Controls/RadioButtons';
import ScrollToTop from '../Controls/ScrollToTop';

import Freelancer from './Resume/Freelancer';
import WorkExperience from './Resume/WorkExperience';
import AreasOfExpertise from './Resume/AreasOfExpertise';
import SkillMatrix from './Resume/SkillMatrix';
import Domains from './Resume/Domains';
import Education from './Resume/Education';
import Languages from './Resume/Languages';

import './AppBodyCommon.css';
import './Resume.css';

class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRBIndex: 0
    };
  }

  componentDidMount() {
    this.setURLPath();
  }

  setURLPath = (tab) => {
    //var url = window.location.origin + "/experience";

    //window.history.pushState({}, '', url);
  }

  rbJson = [
    {
      "label": "Freelance Work",
      "text": "Currently on-going assignments with different clients."
    },
    {
      "label": "Work Experience",
      "text": "Demonstrated history of work across diverse industries and projects."
    },
    {
      "label": "Areas Of Expertise",
      "text": "Proficiency in the areas of architectural & technical design, solution design, programming in several tech stacks, UX/UI designs and app development, DevOps automation, IT & secutiry and more."
    },
    {
      "label": "Skill Matrix",
      "text": "Comprehensive skill set encompassing various software disciplines."
    },
    {
      "label": "Domains",
      "text": "Competent across a range of domains, consistently delivering quality results."
    },
    {
      "label": "Education",
      "text": "Educational background strengthened by relevant qualifications and ongoing learning."
    },
    {
      "label": "Languages",
      "text": "Fluent in multiple languages, skilled at communicating & documenting complex concepts."
    }
  ]

  handleRadioButtonsStateChanges = (index) => {
    console.log("in handleRadioButtonsStateChanges " + index);

    this.setState({ selectedRBIndex: index });

  }

  render() {
    return (
      <div>
        <AppBodyInnerHeader title="EXPERIENCE" summary="As a seasoned Software Engineer with 26 years of experience, I specialize in <span style='color: blue; font-style: oblique;  font-weight: 500'>leading, managing, architecting, designing, and programming</span>. I have a proven track record of delivering comprehensive IT solutions across various project phases of scale & complexities, in multitude of tech stacks, and business domains."
          controlsComponent={RadioButtons} data={this.rbJson} OnStateChange={this.handleRadioButtonsStateChanges} />

        <div className='app-tab-items'>
          <div className={`${this.state.selectedRBIndex === 0 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <Freelancer />
          </div>
          <div className={`${this.state.selectedRBIndex === 1 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <WorkExperience />
          </div>

          <div className={`${this.state.selectedRBIndex === 2 ? 'app-tab-item visible' : 'app-tab-item invisible'}`} style={{ paddingBottom: '12px' }}>
            <AreasOfExpertise />
          </div>

          <div className={`${this.state.selectedRBIndex === 3 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <SkillMatrix />
          </div>

          <div className={`${this.state.selectedRBIndex === 4 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <Domains />
          </div>

          <div className={`${this.state.selectedRBIndex === 5 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <Education />
          </div>

          <div className={`${this.state.selectedRBIndex === 6 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <Languages />
          </div>
        </div>
        <ScrollToTop></ScrollToTop>
      </div>
    );
  }
}

export default Resume;

