const freeLancerJSON = {
    "data": [
        {
            "company": "Freelancer (Current)",
            "icon": "./images/companies/freelancer.jpg",
            "description": "Working on several freelance work spanning across Architectural consulting, System Review, Full-stack Development, Programming, Technical Training, Tech Content Creation, Tech Content Reviews etc.",
            "website": "rameshv.me",
            "start_date": "2023, July",
            "end_date": "Till Date",
            "location": "Chennai, India",
            "role": "Freelancer - Architectural Consulting, System Development, Tech Content Creation, Tech Content Reviews etc.",
            "clients": "Nexval, Dialectica, Acadecraft, IIHT, Cognizant (via NIIT)",
            "projects": [
                "Architectural Consulting for <span style='color: blue; font-weight: 500'>Nexval</span>. Performing code reviews, establishing system stability, cost control, and performance improvements.",
                "Expert Adviser for the clients of <span style='color: blue; font-weight: 500'>Dialectica</span> in the areas of Data Architecture",
                "Technical Content reviewer for <span style='color: blue; font-weight: 500'>Acadecraft</span>. Reviewing technical content for accuracy, relevance, and completeness.",
                "Technical Content Creation for <span style='color: blue; font-weight: 500'>IIHT</span>. Creating guided labs in the area of AI/ML/Generative AI/LLM/NLP/Prompt Engineering in Java, .NET & Python",
                "Technical Trainer for <span style='color: blue; font-weight: 500'>Cognizant (via NIIT)</span>. Training students in the area of AI/ML/Generative AI/LLM/NLP/Prompt Engineering in Java, .NET & JavaScript"
            ],
            "responsibilities": [
                "Expert architectural & design consultant",
                "Provide migration strategies for clients moving to microservices based cloud native architecture",
                "Full stack Programmer/Consultant developing system ground-up or enhancing existing systems",
                "Technical Content Creation of On-Demand Videos, Guided Labs, Decks, , Assessments & Quizzes, Case Studies, Evaluations in several technical areas",
                "Expert adviser for clients in areas of solution architecture, system design, and performance improvements",
            ],
            "technologies": [
                ".NET",
                "C#",
                "Java",
                "Java Spring",
                "Java Spring Boot",
                "Java Spring Cloud",
                "Python",
                "C++",
                "NodeJS",
                "Angular, React, VueJS",
                "HTML, CSS, Javascript, TypeScript",
                "Kafka",
                "Redis",
                "Microservices, MVC, MVVM, MVW, Cloud Native - SaaS, PaaS, IaaS",
                "Kubernetes",
                "Docker",
                "Jenkins"
            ],
            "databases": [
                "MySQL",
                "SQL Server",
                "MongoDB",
                "CosmosDB",
                "Pinecone",
                "ElasticSearch",
                "Oracle",
                "Data Lake",
                "Data Warehousing"
            ],
            "cloudservices": ["Azure", "AWS", "GCP", "Hetzner", "Cloud Native", "Serverless archteicture"],
            "tools": [
                "ElasticSearch",
                "Kibana",
                "Grafana",
                "Prometheus",
                "New Relic",
                "Azure Monitor",
                "Azure Application Insights",
                "Azure Log Analytics",
                "Jira",
                "Azure DevOps",
                "Slack",
                "Git",
                "BitBucket",
                "GitLab",
                "Visual Paradigm",
                "Confluence",
                "Micosoft Office",
                "Google Docs"
            ]
        },
        {
            "company": "CONSULTANT",
            "icon": "./images/companies/freelancer.jpg",
            "description": "Worked as an Independent Consultant",
            "website": "rameshv.me",
            "start_date": "2006, FEB",
            "end_date": "2009, JUN",
            "location": "Chennai, India",
            "role": "FREELANCER",
            "clients": "Lister Technologies, Athenanet, Tejas Soft, Indepth, GiftPay",
            "responsibilities": [
                "Bid for Projects",
                "Develop Projects", 
                "Delivery and Support"
            ],
            "projects": [
                "Developing a Gift Card portal with buy, sell and auction sides for a <span style='color: blue; font-weight: 500'>client in the US.</span>",
                "Worked on Modules integration systems, to extend the CMS for a client in <span style='color: blue; font-weight: 500'>Chile, South America.</span>",
                "Worked on a loan processing system with an underwriting engine for a <span style='color: blue; font-weight: 500'>client in New York, US.</span>",
                "Developed a CV portal using for a <span style='color: blue; font-weight: 500'>client in Canada.</span>",
                "Developed export functionality in a BI product for a company called <span style='color: blue; font-weight: 500'>In Depth Web in Pennsylvania, US.</span>",
                "Developing a generic resourcing engine (GRE) for Lister Technologies, a <span style='color: blue; font-weight: 500'>client in Chennai.</span>.",
                "Developed a complete Organization management system using the generic resourcing engine mentioned above.",
                "Worked on a System Stabilization project for <span style='color: blue; font-weight: 500'>AthenNetIndia, Chennai.</span>.",
                "Worked on a Business Intelligence portal for <span style='color: blue; font-weight: 500'>In Depth Web, Chennai.</span>.",
                "Worked for <span style='color: blue; font-weight: 500'>Tejas soft, US</span> on advance payroll system."
            ],
            "technologies": [
                "ASP.NET 2.0",
                "C#",
                "Telerik",
                "AJAX",
                "SiteFinity 3.1",
                "Nolics 2005",
                "JBoss",
                "Rules ML",
                "PHP",
                "Java",
                "J2EE",
                "Java Servlets",
                "JSP",
                "EJB",
                "SyncFusion",
                ".NET",
                "JDBC",
                "XML",
                "XSLT"
            ],
            "os": [],
            "databases": ["MySQL", "SQL Server", "Oracle", "Postgres"],
            "cloudservices": [],
            "tools": [
                "Microsoft Office",
                "Visual Studio",
                "IIS"
            ]
        }
    ]
}

function getfreeLancerJSON() {
    return freeLancerJSON;
}

export default getfreeLancerJSON; 
