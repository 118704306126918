import React, { Component } from 'react';
import BulletedTree from '../../Controls/BulletedTree';

import getSkillMatrix from '../Resume/data/SkillMatrixJSON';

import '../AppBodyCommon.css'
import '../Resume.css';
import './Domains.css';

class Domains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainsJSON: [],
    };
  }

  skillsJSON = [];

  titleStyle = {
    paddingLeft: '4px',
    fontSize: '15px',
    color: 'black',
    fontVariant: 'small-caps'
  }

  listStyle = {
    paddingLeft: '8px',
    paddingTop: '8px',

    marginTop: '8px',
    color: 'blue',
    boxShadow: '3px 3px 4px 1px rgba(0 0 0 /50%)',
    borderRadius: '4px',
    backgroundColor: 'rgba(250, 250, 212, .7)'
  };

  subListStyle = {
    paddingLeft: '2px',
    paddingTop: '2px',
    paddingRight: '4px',
    color: 'blue',
    fontWeight: '500'
  };


  componentDidMount() {
    this.skillsJSON = getSkillMatrix();

    this.initDomainsJSON();
  }

  initDomainsJSON = () => {
    let domainsJSONTemp = [];

    this.skillsJSON.data.forEach((skill) => {
      if (skill.title === "Domains") {
        domainsJSONTemp.push({ title: skill.title, list: skill.list });
        return; // Exit the forEach loop and the initDomainsJSON function
      }
    });

    this.setState({ domainsJSON: domainsJSONTemp });
  }

  render() {
    return (
      <div className='app-tab-item-container'>
        <div className='app-tab-item-container-items zero-top'>
          <div className='app-tab-item-container-item'>
            <BulletedTree title="" titleStyle={this.titleStyle} listStyle={this.listStyle} subListStyle={this.subListStyle} showParentBullet={false} data={getSkillMatrix().data} filter={["Domains"]} />
          </div>
        </div>
      </div>
    );
  }
}

export default Domains;

