import React from 'react';
import Switch from 'react-switch'

import './SwitchControl.css';

class SwitchControl extends React.Component {
    render() {
        return (
            <div className='switch-control'>
                <div className='switch-label-text'>{this.props.label}</div>
                <Switch
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    handleDiameter={18}
                    boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                    height={14}
                    width={48}/>
            </div>
        );
    }
}

export default SwitchControl;