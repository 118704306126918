import React, { Component } from 'react';
import './AppMain.css';

import AppHeader from '../AppHeader/AppHeader';
import AppBody from '../AppBody/AppBody';

class AppExperience extends Component {

  componentDidMount() {
    //const url = window.location.origin + "/experience";
    //window.history.pushState({}, '', url);
    //window.location.href = url;
  }

  render() {
    return (
      <div className="AppMain">
        <AppHeader />
        <AppBody activeTab='tab2'/>
      </div>
    );
  }
}

export default AppExperience;