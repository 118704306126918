import React, { Component } from 'react';

import BulletedTree from '../../Controls/BulletedTree';

import getTechnicalArticlesJSON from './data/TechArticlesJSON';

import '../AppBodyCommon.css';
import '../FreeContent.css';
import './TechnicalArticles.css';

class TechnicalArticles extends Component {
  filterJSON = ["Architecture & Tech Design", "Java", "Microsoft", "Cloud Service Providers & Services", "Databases & Data Engineering", "Specific Technologies", "Setup", "Build & Deploy", "General"];
  
  
  titleStyle = {
    fontSize: '15px',
    FontFace: 'Arial',
    fontVariant: 'small-caps',
    padding: '2px 2px 2px 5px',
    boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
    borderRadius: '4px',
    marginTop:'12px',
    marginBottom: '12px',
    backgroundColor: 'rgba(250, 250, 212, .7)',
    width: 'fit-content',
      paddingRight: '6px',
    paddingBottom: '4px'
  }
  
  subListStyle2 = { paddingTop: '2px', paddingLeft: '2px'};
  
  render() {
    return (
      <div className='app-tab-item-container'>
        <div className='app-tab-item-container-items zero-top'>
          <div className='app-tab-item-container-item'>
            <BulletedTree title="" data={getTechnicalArticlesJSON().data} titleStyle={this.titleStyle} anchorInNewWindow={false} filter={this.filterJSON} subListStyle={this.subListStyle2} showParentBullet={false} />
          </div>
        </div>
      </div>
    )
  }
};

export default TechnicalArticles;

