const techArticlesJSON = {
  "data": [
    {
      "title": "Cloud Service Providers & Services",
      "list": [
        {
          "title": "Azure_Developer_Guide_eBook_en-GB.pdf",
          "link": "./files/tech-articles/cloud/Azure_Developer_Guide_eBook_en-GB.pdf",
          "description": "Comprehensive guidebook for developers on utilizing Azure services effectively and efficiently."
        },
        {
          "title": "Azure_for_Architects.pdf",
          "link": "./files/tech-articles/cloud/Azure_for_Architects.pdf",
          "description": "Resource for architects exploring Azure cloud services for building scalable solutions."
        }
      ]
    },
    {
      "title": "Architecture & Tech Design",
      "list": [
        {
          "title": "Architecture.doc",
          "link": "./files/tech-articles/arch/Architecture.doc",
          "description": "Document detailing architectural principles and best practices for system design."
        },
        {
          "title": "Why Tech Designing.pptx",
          "link": "./files/tech-articles/arch/Why Tech Designing.pptx",
          "description": "Presentation highlighting the importance and techniques of technical system design."
        },
        {
          "title": "Diagramming with Visual Paradigm.docx",
          "link": "./files/tech-articles/arch/Diagramming with Visual Paradigm.docx",
          "description": "Guide on using Visual Paradigm for diagramming and visualizing system architectures."
        },
        {
          "title": "Sd_Blueprint.pdf",
          "link": "./files/tech-articles/arch/Sd_Blueprint.pdf",
          "description": "Blueprint outlining the software design of a specific system or application."
        },
        {
          "title": "sys design byte by byte.pdf",
          "link": "./files/tech-articles/arch/sys design byte by byte.pdf",
          "description": "In-depth analysis of system design principles and methodologies byte by byte."
        }
      ]
    },
    {
      "title": "Databases & Data Engineering",
      "list": [
        {
          "title": "spring-data-neo4j-reference.pdf",
          "link": "./files/tech-articles/data/spring-data-neo4j-reference.pdf",
          "description": "Reference guide for utilizing Spring Data with Neo4j graph databases effectively."
        },
        {
          "title": "Cache-Eviction-To-Achieve-CDC.pdf",
          "link": "./files/tech-articles/data/Cache-Eviction-To-Achieve-CDC.pdf",
          "description": "Techniques for cache eviction to maintain consistency and achieve change data capture (CDC)."
        }
      ]
    },
    {
      "title": "Java",
      "list": [
        {
          "title": "How-To-Write-Unit-Test cases-using-JUnit5-and-Mockito.pdf",
          "link": "./files/tech-articles/java/How-To-Write-Unit-Test cases-using-JUnit5-and-Mockito.pdf",
          "description": "Tutorial on writing unit test cases using JUnit5 and Mockito frameworks effectively."
        },
        {
          "title": "code-deploy-scale-java-your-way.pdf",
          "link": "./files/tech-articles/java/code-deploy-scale-java-your-way.pdf",
          "description": "Strategies for deploying and scaling Java applications tailored to specific needs."
        }
      ]
    },
    {
      "title": "Microsoft",
      "list": [
        {
          "title": "Advantages of COM Classes.doc",
          "link": "./files/tech-articles/ms/Advantages of COM Classes.doc",
          "description": "Detailed examination of the benefits and use cases of Component Object Model (COM) classes."
        }
      ]
    },
    {
      "title": "Specific Technologies",
      "children": [
        {
          "title": "Kafka",
          "list": [
            {
              "title": "Database-Synchronization-Using-Kafka.pdf",
              "link": "./files/tech-articles/specific tech/kafka/Database-Synchronization-Using-Kafka.pdf",
              "description": "Guide on synchronizing databases using Kafka messaging system efficiently."
            },
            {
              "title": "Kafka-Source-Sink-Connector.pdf",
              "link": "./files/tech-articles/specific tech/kafka/Kafka-Source-Sink-Connector.pdf",
              "description": "Documentation on Kafka's source-sink connector for data integration and movement."
            },
            {
              "title": "MySQL-to-Kafka.pdf",
              "link": "./files/tech-articles/specific tech/kafka/MySQL-to-Kafka.pdf",
              "description": "Guide on integrating MySQL databases with Kafka messaging system effectively."
            }
          ]
        },
        {
          "title": "Web Extraction",
          "list": [
            {
              "title": "Xavi_Extraction.pdf",
              "link": "./files/tech-articles/specific tech/web extraction/Xavi_Extraction.pdf",
              "description": "Techniques and methods for web data extraction using Xavi tool efficiently."
            }
          ]
        },
        {
          "title": "Security",
          "list": [
            {
              "title": "S360_2015_DevSecOps_Program.pptx",
              "link": "./files/tech-articles/specific tech/security/S360_2015_DevSecOps_Program.pptx",
              "description": "Presentation outlining a comprehensive DevSecOps program for security integration."
            }
          ]
        }
      ]
    },
    {
      "title": "General",
      "list": [
        {
          "title": "DataStructures And Algorithms.doc",
          "link": "./files/tech-articles/general/DataStructures And Algorithms.doc",
          "description": "Comprehensive document covering various data structures and algorithms used in programming."
        },
        {
          "title": "WP_The_Multi-core_Dilemma_20100723.pdf",
          "link": "./files/tech-articles/general/WP_The_Multi-core_Dilemma_20100723.pdf",
          "description": "Whitepaper discussing the challenges and solutions related to multi-core processors."
        }
      ]
    },
    {
      "title": "Setup",
      "list": [
        {
          "title": "Setup Visual Paradigm.docx",
          "link": "./files/tech-articles/setup/Setup Visual Paradigm.docx",
          "description": "Instructions for setting up Visual Paradigm tool for modeling and design purposes."
        }
      ]
    },
    {
      "title": "Build & Deploy",
      "list": [
        {
          "title": "Kubernetes_Tools_Ecosystem.pdf",
          "link": "./files/tech-articles/build/Kubernetes_Tools_Ecosystem.pdf",
          "description": "Overview of the tools and ecosystem surrounding Kubernetes container orchestration."
        }
      ]
    }
  ]
}

function getTechArticlesJSON(){
  return techArticlesJSON;
} 

export default getTechArticlesJSON;