import React, { Component } from 'react';
import './RadioButtons.css';

class RadioButtons extends Component {
  myRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      RbLabels: this.props.data,
      selectedIndex: this.props.selectedIndex !== undefined ? parseInt(this.props.selectedIndex) : 0,
      labelText: ''
    };

    console.log("in constructor of RadioButtons " + this.state.RbLabels);
    console.log("sel index=" + this.state.selectedIndex)
  }

  toggleRBState = (index) => {
    console.log("in toggleRBState index " + index);
    this.setState({ selectedIndex: index });

    this.setState({ labelText: this.state.RbLabels[index].text });

    console.log("in constructor of RadioButtons state change" + this.props.OnStateChange);
    this.props.OnStateChange(index);
  }

  componentDidMount() {
    console.log("in componentDidMount");
    console.log("sel index=" + this.state.selectedIndex)

    //this.toggleRBState(0);
    let id = "rb" + this.state.RbLabels[this.state.selectedIndex].label.replaceAll(" ", "")
    document.getElementById(id).click()
  }

  render() {
    return (
      
        <div class=" radiobuttons-container">
          <div class="radiobuttons-container-outer">
        {this.state.RbLabels.map((rb, index) => (

          <label className="radiobutton-container" key={index}>
            <input
              id={`rb${this.state.RbLabels[index].label.replaceAll(" ", "")}`}
              name={`rb${this.state.RbLabels[0].label.replaceAll(" ", "")}`}
              type='radio'
              onChange={() => this.toggleRBState(index)}
              selected={this.state.selectedIndex === index ? true : false} />

            <span className="checkmark-radio"></span>
            <span className="radiobutton-label" style={{ fontWeight: this.state.selectedIndex === index ? 'bold' : '600', color: this.state.selectedIndex === index ? 'blue' : 'black' }}>{this.state.RbLabels[index].label}</span>
          </label>
        ))}
        </div>
        <div className='radiobutton-text'>
          {this.state.labelText !== '' ? this.state.labelText : null}
        </div>
      </div>
    );
  }
}

export default RadioButtons;