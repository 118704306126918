import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import ParallaxBackground from './ParallaxBackground';

import AppMain from './components/Views/AppMain';

import AppExperience from './components/Views/AppExperience';
import AppServices from './components/Views/AppServices';
import AppFreeContent from './components/Views/AppFreeContent';

import AppArchServices from './components/Views/AppArchServices';
import AppProgrammingServices from './components/Views/AppProgrammingServices';
import AppContentMakerServices from './components/Views/AppContentMakerServices';

import AppTopics from './components/Views/AppTopics';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";


class App extends Component {

  componentDidMount() {
    const browserHistory = createBrowserHistory({ basename: '' });
    /* const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: "InstrumentationKey=73885f58-ab54-4d12-8c9e-0136914dc4ee;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/",
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
      }
    });
    appInsights.loadAppInsights(); */
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" component={AppMain} />
            <Route exact path="/experience" component={AppExperience} />
            <Route exact path="/services" component={AppServices} />
            <Route exact path="/services/architect" component={AppArchServices} />
            <Route exact path="/services/programmer" component={AppProgrammingServices} />
            <Route exact path="/services/content-maker" component={AppContentMakerServices} />
            <Route exact path="/free-content" component={AppFreeContent} />
            <Route exact path="/free-content/topics" component={AppTopics} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;