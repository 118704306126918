import React from 'react';

import './BulletedTree.css';

class BulletedTreeNode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            node: this.props.node
        };
    }

    isJSON = (jsonObj) => {
        return typeof jsonObj === 'object';
    }

    linkOpensInBrowser = (link) => {
        link = link.toString();
        return link.startsWith('http') || link.startsWith('www') || link.endsWith('.pdf');
    }

    isVideoFile = (link) => {
        return link.toString().endsWith('.mp4');
    }


    displayTextNode(listNodeJSON) {
        return (<div className='bulleted-tree-list-item-text' style={this.props.subListStyle}>{listNodeJSON}</div>);
    }

    displayLinkNodes(listNodeJSON) {
        return (
            <div>
                {
                    listNodeJSON.link !== undefined && this.isVideoFile(listNodeJSON.link) && this.displayVideoFile(listNodeJSON)
                }
                {
                    listNodeJSON.link !== undefined && !this.isVideoFile(listNodeJSON.link) && this.displayAnchor(listNodeJSON)
                }
            </div>
        );
    }

    displayAnchor(listNodeJSON, anchorInNewWindow) {
        return (anchorInNewWindow || this.linkOpensInBrowser(listNodeJSON.link)) ? (
            <div>
                <a href={`${listNodeJSON.link}`} target="_blank" rel='noreferrer'>{listNodeJSON.title}</a>
                <span>&nbsp;- {listNodeJSON.description}</span>
            </div>
        ) : (
            <div>
                <a href={`${listNodeJSON.link}`}>{listNodeJSON.title}</a>
                <span>&nbsp;- {listNodeJSON.description}</span>
            </div>
        );
    }

    displayVideoFile(listNodeJSON) {
        return (
            <div>
                <span>{listNodeJSON.title}</span>
                <span>&nbsp;- {listNodeJSON.description}</span>
                <br></br>
                <video width="245" height="137.8125" autoPlay={false} controls preload='none' style={{ marginTop: '4px' }}>
                    <source src={`${listNodeJSON.link}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        );
    }

    render() {
        const node = this.state.node;

        const listStyle = this.props.listStyle;
        const titleStyle = this.props.titleStyle;
        const subTitleStyle = this.props.subTitleStyle;
        const subListStyle = this.props.subListStyle || {fontSize: '13px'};

        let showParentBullet = true;
        if (this.props.showParentBullet !== undefined) {
            showParentBullet = this.props.showParentBullet;
        }

        const listStyleType = {listStyleType: showParentBullet ? 'circle' : 'square'};

        let nodeTitleClass = 'bulleted-tree-node-title';
        if (!showParentBullet) {
            nodeTitleClass = 'bulleted-tree-node-title-no-indent'
        }

        let LIClass = 'li-style-parent';
        if (!showParentBullet)
            LIClass = 'li-style-parent-no-indent';

        return (
            <li className={LIClass} style={{ listStyleType: showParentBullet ? 'disc' : 'none' }}>
                {node.title !== "" && (
                    <div className={nodeTitleClass} style={titleStyle}>{node.title}</div>
                )}

                {node.children && node.children.length > 0 && (
                    <ul className='bulleted-child-list'>
                        {node.children.map((childNode, index) => (
                            <BulletedTreeNode key={index} node={childNode}  titleStyle={subTitleStyle} listStyle={listStyle} subListStyle={this.props.subListStyle} anchorInNewWindow={this.props.anchorInNewWindow} />
                        ))}
                    </ul>
                )}

                
                {node.list && node.list.length > 0 && (
                    <ul className='bulleted-list' style={this.props.subListStyle}>
                        {
                            node.list.map((listNodeJSON, index) => (
                                listNodeJSON === '-' ? (<li key={index}  style={{listStyleType: 'none'}}>&nbsp;</li>) :
                                <li key={index} className='li-style' style={{...listStyleType, ...subListStyle}}>
                                    {
                                        this.isJSON(listNodeJSON) === false ? this.displayTextNode(listNodeJSON) : this.displayLinkNodes(listNodeJSON, this.props.anchorInNewWindow)
                                    }
                                </li>
                            ))
                        }
                    </ul>
                )}
            </li>
        );
    }
}

class BulletedTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataToDisplay: []
        }
    }

    getJSONs = (dataJSON, filterJSON) => {
        if (filterJSON === undefined || filterJSON.length === 0)
            return dataJSON;

        let tdsJSON = [];
        filterJSON.forEach((filter) => {
            dataJSON.forEach((data) => {
                if (data.title === filter) {
                    tdsJSON.push(data);
                }
            }
            );
        });

        return tdsJSON;
    }

    componentDidMount() {
        const data = this.props.data;
        const filter = this.props.filter;

        this.setState({ dataToDisplay: this.getJSONs(data, filter) });
    }

    render() {
        const titleStyle = this.props.titleStyle;
        const subTitleStyle = this.props.subTitleStyle;
        const listStyle = this.props.listStyle;
        let showParentBullet = true;
        if (this.props.showParentBullet !== undefined) {
            showParentBullet = this.props.showParentBullet;
        }

        let treeRootListPaddingStyle = { paddingRight: '0px' };
        if (listStyle !== undefined && Object.keys(listStyle).length > 0) {
            treeRootListPaddingStyle = { paddingRight: '4px' };
        }

        let ULClass = showParentBullet ? 'bulleted-list-root' : 'bulleted-list-root-no-indent';

        return (
            <div className='bulleted-tree' style={{ ...listStyle, ...treeRootListPaddingStyle }}>
                <ul className={ULClass}>
                    {this.state.dataToDisplay !== undefined && this.state.dataToDisplay.length > 0 ? (
                        this.state.dataToDisplay.map((node, index) => (
                            <BulletedTreeNode key={index} node={node} titleStyle={titleStyle} subTitleStyle={subTitleStyle} listStyle={listStyle} subListStyle={this.props.subListStyle} showParentBullet={showParentBullet} anchorInNewWindow={this.props.anchorInNewWindow} />
                        ))) : (
                        <div className='bulleted-tree-node-title'>No data to display</div>
                    )}
                </ul>
            </div>
        );
    }
}

export default BulletedTree;