import React, { Component } from 'react';

import './WorkExperienceCard.css';

class WorkExperienceCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCardVisible: true,
            WorkExperienceJSON: this.props.workExperience,
            techStacks: this.getJoinedTechString(this.props.workExperience.technologies),
            databases: this.getJoinedTechString(this.props.workExperience.databases),
            tools: this.getJoinedTechString(this.props.workExperience.tools),
            cloudservices: this.getJoinedTechString(this.props.workExperience.cloudservices)
        };
    }

    getJoinedTechString = (techArray) => {
        if (techArray !== undefined && techArray !== "") {
            return techArray.join(', ');
        }

        return "";
    }

    toggleCardVisibility = () => {
        this.setState(prevState => ({ isCardVisible: !prevState.isCardVisible }));
    }

    render() {
        return (
            <div className="work-exp-card-container">
                {this.state.isCardVisible && (
                    <div className="work-exp-card-main-content">
                        <div>&nbsp;&nbsp;</div>
                        <div className="work-exp-card">
                            <div className="work-exp-card-item">
                                <div className="work-exp-card-item-header-row">
                                    <span className="work-exp-card-item-header-label">
                                        Company:&nbsp;
                                    </span>
                                    <span className="work-exp-card-item-content" style={{fontSize: '14px'}}>
                                        {this.state.WorkExperienceJSON.company}
                                    </span>
                                </div>
                                <div className="work-exp-card-item-content">
                                    {this.state.WorkExperienceJSON.description}
                                </div>
                                <div className="work-exp-card-item-content">
                                    <a href={this.state.WorkExperienceJSON.website}>{this.state.WorkExperienceJSON.website}</a>
                                </div>
                            </div>

                            {this.props.isRoleVisible && (
                                <div className="work-exp-card-item">
                                    <div className="work-exp-card-item-header-row">
                                        <span className="work-exp-card-item-header-label">
                                            Role:&nbsp;
                                        </span>
                                        <span className="work-exp-card-item-content"  style={{fontSize: '14px'}}>
                                            {this.state.WorkExperienceJSON.role}
                                        </span>
                                    </div>
                                </div>
                            )}

                            {this.props.isResponsibilityVisible && (
                                <div className="work-exp-card-item">
                                    <div className="work-exp-card-item-header-label">
                                        Responsibilities
                                    </div>
                                    <div className="work-exp-card-item-list">
                                        {this.state.WorkExperienceJSON.responsibilities.map((responsibility, index) => (
                                            <div key={`domain${index}`} style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '18px', paddingRight: '20px' }}>
                                                    <i className="fas fa-check" style={{ color: 'blue' }}></i>
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    {responsibility}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {this.props.isProjectVisible && (
                                <div className="work-exp-card-item">
                                    <div className="work-exp-card-item-header-label">
                                        Projects
                                    </div>
                                    <div className="work-exp-card-item-list">
                                        {this.state.WorkExperienceJSON.projects.map((project, index) => (
                                            <div key={`domain${index}`} style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '18px', paddingRight: '20px' }}>
                                                    <i className="fas fa-check" style={{ color: 'blue' }}></i>
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                <div dangerouslySetInnerHTML={{ __html: project }}></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {this.props.isTechnologyVisible && (
                                <div className="work-exp-card-item-techs" style={{ width: '100%' }}>
                                    <div className="work-exp-card-item-header-label">
                                        Technologies
                                    </div>

                                    <div className='tech-topic-parent'>
                                        {this.state.techStacks !== undefined && this.state.techStacks !== "" && (
                                        <div className="work-exp-card-item-content" style={{ fontSize: '13px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>
                                                <div className='tech-topic-header' style={{ borderTopLeftRadius: '0px' }}>
                                                    Tech Stacks
                                                </div>
                                                <div className='tech-topic-text' style={{ borderTopRightRadius: '0px' }}>
                                                    {this.state.techStacks}
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                        {this.state.databases !== undefined && this.state.databases !== "" && (
                                        <div className="work-exp-card-item-content" style={{ fontSize: '13px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                <div className='tech-topic-header'>
                                                    Databases
                                                    </div>
                                                <div className='tech-topic-text'>
                                                    {this.state.databases}
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                        {this.state.cloudservices !== undefined && this.state.cloudservices !== "" && (
                                        <div className="work-exp-card-item-content" style={{ fontSize: '13px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                <div className='tech-topic-header'>
                                                    Cloud Services
                                                    </div>
                                                <div className='tech-topic-text'>
                                                    {this.state.cloudservices}
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                        {this.state.tools !== undefined && this.state.tools !== "" && (
                                        <div className="work-exp-card-item-content" style={{ fontSize: '13px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
                                                <div className='tech-topic-header' style={{ borderBottomLeftRadius: '0px' }}>
                                                    Tools
                                                    </div>
                                                <div className='tech-topic-text' style={{ borderBottomRightRadius: '0px' }}>
                                                    {this.state.tools}
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default WorkExperienceCard;