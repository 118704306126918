import React, { Component } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import CheckBoxes from '../../Controls/CheckBoxes';

import getWorkExperienceJSON from './data/WorkExperienceJSON';
import WorkExperienceCard from './WorkExperienceCard';

import '../AppBodyCommon.css'
import '../Resume.css';
import './WorkExperience.css';

class WorkExperience extends Component {
  cbJson = ["Roles", "Responibilities", "Projects", "Technologies"];

  constructor(props) {
    super(props);
    this.state = {
      workExperiences: getWorkExperienceJSON(),

      isCompanyVisible: true,
      isRoleVisible: true,
      isResponsibilityVisible: true,
      isProjectVisible: false,
      isTechnologyVisible: false
    };
  }

  handleCheckBoxesStateChanges = (selected) => {
    console.log("in handleCheckBoxesStateChanges " + selected);

    this.setState({ isRoleVisible: selected[0] });
    this.setState({ isResponsibilityVisible: selected[1] });
    this.setState({ isProjectVisible: selected[2] });
    this.setState({ isTechnologyVisible: selected[3] });
  }

  render() {
    return (
      <div className='app-tab-item-container container work-exp-body'>
        <div className='app-tab-item-container-items extra-top'>
          <div className='app-tab-item-container-item'>
            <CheckBoxes data={this.cbJson} selectAll={false} initialSelected={[true, true, false, false]} onStateChange={this.handleCheckBoxesStateChanges} />
            <div style={{ paddingTop: '10px' }}>
              <VerticalTimeline layout="1-column">
                {this.state.workExperiences.workExp.map((workExp, index) => (
                  <VerticalTimelineElement
                    key={index}
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#000', paddingLeft: '20px' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    iconStyle={{ backgroundColor: 'rgb(199, 173, 173)', color: '#000' }}
                    icon={<img src={workExp.icon} width="40px" height="40px" alt="custom-icon" style={{ borderRadius: '50%', borderColor: 'blue' }}></img>}
                  >
                    <div className='work-exp-header'>
                      <div className="vertical-timeline-element-date" style={{ color: 'yellow', paddingLeft: ' 8px', textWrap: 'wrap', width: '40%'}}>{workExp.start_date} - {workExp.end_date}</div>
                      <div className="vertical-timeline-element-date" style={{ color: 'yellow', paddingLeft: ' 6px', textAlign: 'right', width:'60%'}}> {workExp.location}</div>
                    </div>

                    <WorkExperienceCard key={index} workExperience={workExp}
                      isRoleVisible={this.state.isRoleVisible}
                      isResponsibilityVisible={this.state.isResponsibilityVisible}
                      isProjectVisible={this.state.isProjectVisible}
                      isTechnologyVisible={this.state.isTechnologyVisible} />

                  </VerticalTimelineElement>
                ))}

              </VerticalTimeline>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkExperience;

