const ArchServiceOfferingsJSON = {
    "data": [
        {
            "title": "System Architecture Services",
            "children": [
                {
                    "title": "General Architecture",
                    "list": [
                        "Design scalable, resilient architectures using microservices, distributed systems, and cloud-native technologies for optimal performance and reliability.",
                        "Conduct thorough architectural reviews, identifying potential bottlenecks and proposing solutions to optimize system efficiency and resource utilization.",
                        "Define architectural patterns and best practices, ensuring consistency and alignment across development teams and projects.",
                        "Design end-to-end solutions, including front-end, back-end, and data layers, to meet business requirements and user needs."
                    ],
                },
                {
                    "title": "Technical Architecture",
                    "list": [
                        "Design scalable, resilient, and secure application architectures for web, mobile, and cloud-based systems.",
                        "Architect data management solutions, including database design, data modeling, and data migration strategies, ensuring data integrity, availability, and scalability.",
                        "Design robust and scalable API architectures, ensuring interoperability and seamless integration across diverse systems and platforms.",
                        "Devise migration strategies, selecting appropriate cloud services and orchestrating migration processes to ensure minimal downtime and maximum scalability.",
                        "Define robust security strategies, including encryption, access control, and authentication mechanisms, to safeguard data and protect against cyber threats.",
                        "Develop architectural blueprints and roadmaps, guiding the implementation of technical solutions and aligning with business objectives."
                    ]
                },
                {
                    "title": "Solution Architecture",
                    "list":
                        [
                            "Crafting comprehensive architectural designs tailored to client needs.",
                            "Seamlessly integrating disparate systems to ensure interoperability and efficiency.",
                            "Providing expert advice and guidance on technical matters to support strategic decision-making.",
                            "Analyzing client requirements to identify key objectives and design effective solutions.",
                            "Optimizing system performance through efficient design and implementation strategies.",
                            "Facilitating communication between stakeholders to ensure alignment and transparency throughout the project lifecycle."
                        ]

                }
            ]
        },
        {
            "title": "Component Archtiecure Services",
            "children": [
                {
                    "title": "Application Archtiecture",
                    "list": [
                        "Craft intuitive and visually appealing user interfaces to enhance user engagement.",
                        "Design seamless user flows for optimal navigation and interaction.",
                        "Create wireframes and prototypes to validate design concepts and gather feedback.",
                        "Optimize UI/UX design for different screen sizes and device resolutions.",
                        "Design and implement responsive web applications using modern front-end frameworks like React, Angular, and Vue.js.",
                        "Design Stores and State Management solutions like Redux, NgRX, RxJS to manage application state and data flow.",
                        "Design accessibility features to ensure inclusivity and compliance.",
                    ]
                },
                {
                    "title": "API Architecture",
                    "list": [
                        "Build and deploy RESTful APIs to facilitate communication between systems.",
                        "Utilize API management platforms to monitor, secure, and document APIs.",
                        "Implement GraphQL APIs for efficient data retrieval and manipulation."
                    ]
                },
                {
                    "title": "Data Architecutre",
                    "list": [
                        "Design database schemas, queries, and stored procedures.",
                        "Evaluate and implement approriate database technologies like SQL, NoSQL, Data Warehouse, Data Lake, Graph, Timeseries, Vector etc.",
                        "Optimize database queries to enhance efficiency.",
                        "Utilize cloud-based storage solutions like Amazon S3 or Google Cloud Storage for data storage.",
                        "Implement cloud-based databases like Amazon RDS or Google Cloud SQL for data management."
                    ]
                },
                {
                    "title": "Cloud Native Architecure",
                    "list": [
                        "Utilize cloud services like AWS Lambda or Google Cloud Functions for serverless computing.",
                        "Deploy applications using containerization technologies like Docker and Kubernetes.",
                        "Implement cloud-native architectures leveraging microservices and serverless paradigms."
                    ]
                }
            ]
        },
        {
            "title": "AI/ML Architecture Services",
            children: [
                {
                    "title": "Data Science/AI/ML",
                    "list": [
                        "Design AI/ML architectures, including data pipelines, model training, and inference systems, to support machine learning applications.",
                        "Architect scalable and resilient AI/ML systems, leveraging cloud-native technologies and distributed computing to optimize performance and resource utilization.",
                        "Define data management strategies, including data collection, storage, and preprocessing, to ensure data quality and availability for model training and inference.",
                        "Design AI/ML model architectures, selecting appropriate algorithms and frameworks to meet business objectives and user needs.",
                        "Develop AI/ML architectural blueprints and roadmaps, guiding the implementation of technical solutions and aligning with business objectives."
                    ]
                },
                {
                    "title": "Gen AI Development",
                    "list": [
                        "Designing and implementing conversational AI solutions using ChatGPT to automate customer support, lead generation, and other tasks.",
                        "Fine tuned models: Tailoring ChatGPT and other LLM models to specific domains or use cases by fine-tuning them on relevant datasets.",
                        "Create embeddings, storing in vector databases, and querying them to find similar items or clusters of items or to prompt",
                        "Provide Gen AI services involving prompt engineering, prompting techniques, design with best plactices including bias mitigattion and ethical considerations.",
                        "Implement advanced concepts like functions, RAG, LangChain etc. and more to create advanced Gen AI solutions."
                    ]
                },
                {
                    "title": "NLP",
                    "list": [
                        "Designing and training bespoke NLP models for specific use cases or domains.",
                        "Providing expert guidance and advice on NLP implementation strategies and best practices.",
                        "Text Data Preprocessing: Cleaning, tokenizing, and normalizing text data to prepare it for NLP tasks.",
                        "NLP Model Evaluation and Benchmarking: Assessing NLP model performance using standard benchmarks and metrics.",
                        "Text Classification and Categorization: Building models to automatically categorize text data into predefined categories or labels.",
                        "Named Entity Recognition (NER): Creating models to identify and extract named entities such as people, organizations, and locations from text data.",
                        "Implementing machine translation systems to translate text between different languages, enabling multilingual support.",
                        "Creating systems to generate coherent text passages or summaries from input data, facilitating content creation and synthesis.",
                        "Integrating NLP models into existing applications and deploying them to production environments for seamless integration and optimal performance."

                    ]
                }
            ]
        },
        {
            "title": "Auxiliary Architecture Services",
            "children": [
                {
                    "title": "Performance",
                    "list": [
                        "Analyzing existing systems to identify performance bottlenecks and areas for improvement.",
                        "Designing systems with scalability in mind to handle increasing loads and user demands.",
                        "Conducting thorough reviews of system architecture to identify potential performance issues and propose optimizations.",
                        "Performing load testing to evaluate system performance under various load conditions and identify performance limits.",
                        "Designing and implementing effective caching strategies to reduce latency and improve response times.",
                        "Optimizing database queries, indexes, and schema design to improve data retrieval and processing speed.",
                        "Implementing CDNs to cache and deliver content closer to end-users, reducing latency and improving performance.",
                        "Designing systems using microservices architecture to enable horizontal scaling and improve fault isolation.",
                        "Setting up monitoring tools to track system performance metrics and identify performance degradation in real-time.",
                        "Establishing processes for continuous performance optimization, including regular performance reviews and optimizations.",
                        "Optimizing infrastructure configurations, such as server configurations and network settings, to improve system performance.",
                        "Optimizing continuous integration and continuous deployment pipelines to automate performance testing and ensure performance considerations are addressed early in the development lifecycle."
                    ]
                },
                {
                    "title": "Security",
                    "list": [
                        "Security: Ensure robust encryption methods for data protection.",
                        "Compliance: Adhere to industry regulations and standards for legal compliance.",
                        "Data Integrity: Implement measures to prevent unauthorized data modification.",
                        "Access Control: Define and enforce user access policies and permissions.",
                        "Auditability: Enable comprehensive logging and auditing for accountability.",
                        "Privacy: Safeguard user data and ensure compliance with privacy regulations.",
                    ]
                },
                {
                    "title": "NFRs",
                    "list": [
                        "Maintainability: Develop codebase with clear structure for easy maintenance.",
                        "Portability: Ensure compatibility across diverse platforms and environments.",
                        "Interoperability: Enable seamless integration with external systems and APIs.",
                        "Accessibility: Provide support for users with disabilities and diverse needs.",
                        "Localization: Support multiple languages and cultural preferences.",
                        "Scalability: Design systems to handle increasing loads seamlessly.",
                        "Performance: Optimize response times for enhanced user experience.",
                        "Reliability: Implement fault-tolerant mechanisms to minimize downtime.",
                        "Availability: Ensure high system availability through redundancy measures.",
                        "Usability: Design intuitive interfaces for ease of use and accessibility.",
                        "Fault Tolerance: Implement measures to mitigate the impact of system failures.",
                        "Resilience: Design systems to withstand and recover from failures gracefully.",
                    ]
                },
            ]
        },
        {
            "title": "Architecture Related Services",
            "children": [
                {
                    "title": "Establishing Best Practices",
                    "list": [
                        "Lead architecture governance processes, defining standards, guidelines, and best practices, and ensuring adherence through code reviews and architectural assessments.",
                        "Collaborate with cross-functional teams, including product managers, business analysts, and operations teams, to align technical solutions with business objectives and requirements.",
                        "Stay abreast of emerging technologies, industry trends, and best practices, continuously learning and adapting to drive innovation and maintain technical excellence."
                    ]
                },
                {
                    "title": "Documentation & Diagramming",
                    "list": [
                        "Create comprehensive technical documentation including architecture diagrams, API specifications, and user manuals.",
                        "Produce detailed system diagrams and flowcharts to visualize system components, data flows, and interactions.",
                        "Develop entity-relationship diagrams (ERDs) and data flow diagrams (DFDs) to model data structures and system processes.",
                        "Create architectural blueprints and technical roadmaps to guide development teams and align with business objectives.",
                        "Collaborate with stakeholders to gather requirements and validate design decisions through prototypes and feedback sessions.",
                        "Document software design patterns, coding standards, and best practices to ensure consistency and maintainability of codebase.",
                    ]
                },
                {
                    "title": "Soft Skills",
                    "list": [
                        "Communication: Articulate complex technical concepts to non-technical stakeholders.",
                        "Problem-Solving: Analyze complex problems and develop innovative solutions.",
                        "Creativity: Think critically and develop creative solutions to complex challenges.",
                        "Empathy: Understand and empathize with user needs and pain points.",
                        "Networking: Build and maintain professional relationships with industry peers and experts."
                    ]
                },
            ]
        }
    ]
}

function getArchServiceOfferingsJSON() {
    return ArchServiceOfferingsJSON;
}

export default getArchServiceOfferingsJSON;
