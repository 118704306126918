import React, { Component } from 'react';

import ScrollToTop from '../Controls/ScrollToTop';

import './Home.css';

class Home extends Component {

  render() {
    return (
      <div>
        <span>Welcome to the digital realm of <span className='home-text'>Ramesh</span>, where innovation meets expertise in the Software Universe.</span>
        <br></br>
        <br></br>
        <span>With over 26 years of seasoned experience, I'm your go-to <span className='home-text'>Freelance</span> Consultant hailing from the vibrant tech hub of Chennai, India.</span>
        <br></br>
        <br></br>
        <span>Specializing in <span className='home-text'>Architecture, Programming, System Development, and crafting cutting-edge Technical Content</span>, I'm here to elevate your projects to new heights.</span>
        <br></br>
        <br></br>
        <div className='tabs-explain-block'>
          <div>
            <span>Unlock the potential!&nbsp;</span>
            <span className='home-text-black'>Explore 4 key tabs on the left:</span>
          </div>
          <div className='home-tabs-list'>
            <div className='home-tab'><img src='/images/left-arrow.png' width='20px' alt=''></img><div><span className='home-text-black'>Home Tab</span> - Your starting point for exploration</div></div>
            <div className='home-tab'><img src='/images/left-arrow.png' width='20px' alt=''></img><div><span className='home-text-black'>Consulting Tab</span> - Uncover the full spectrum of services I offer</div></div>
            <div className='home-tab'><img src='/images/left-arrow.png' width='20px' alt=''></img><div><span className='home-text-black'>Experience Tab</span> - Delve into my proven track record of success</div></div>
            <div className='home-tab'><img src='/images/left-arrow.png' width='20px' alt=''></img><div><span className='home-text-black'>Free Content Tab</span> - Free Access to a wealth of valuable resources: templates, guidelines, projects, code snippets and insightful content galore! for <span className='home-text-black'>1000+ Topics</span> in the field of Software & related and more</div></div>
          </div>
        </div>
        <div className='home-tab-text'>
          <span>Ready to take the next step? <span className='home-text'>Contact me</span> to discuss your project needs.</span>
          <span> My <span className='home-text'>Contact details</span> are located at the top right corner of this app.</span>
        </div>
        <ScrollToTop />
      </div >
    )
  }
}

export default Home;