import React, { Component } from 'react';
import RadioButtons from '../../Controls/RadioButtons';
import BulletedTree from '../../Controls/BulletedTree';

import getSkillMatrix from '../Resume/data/SkillMatrixJSON';

import getArchServiceOfferingsJSON from './data/architectureOfferrings';
import SampleDiagrams from './SampleDiagrams';
import { getTechDesignsJSON } from './data/techDesigns';

import '../AppBodyCommon.css';
import '../Services.css';
import './ArchitectureService.css';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
import { FaPersonWalkingDashedLineArrowRight } from 'react-icons/fa6';

class ArchitectureService extends Component {
  rbJson = [
    {
      "label": "Architecture Service Offerings",
      "text": "I specialize in delivering comprehensive architectural solutions. Explore the extensive range of architectural and technical design services I provide."
    },
    {
      "label": "Architectures",
      "text": "Explore my expertise in various architectural designs and technical stacks."
    },
    {
      "label": "Domains",
      "text": "Expertise spanning diverse domains to meet your unique business needs."
    },
    {
      "label": "Sample Designs",
      "text": "Explore few sample architectural design documents."
    },
    {
      "label": "Sample Diagrams",
      "text": "Explore ample collection of detailed architectural and technical diagrams, showcasing my precision and attention to detail"
    }
  ];

  techArch = ["Architectures"];
  techDesigns = ["Tech Designs", "Data Management", "APIs", "UX/UI", "AI/ML/NLP/Gen AI"];
  techStacks = ["Tech Stacks", "Cloud Service Providers", "Design and Documentation Tools"];
  domains = ["Domains"];

  constructor(props) {
    super(props);
    this.state = {
      selectedRBIndex: 0,
      technicalDesignsJSON: getTechDesignsJSON()
    };
  }

  skillsJSON = [];

  titleStyle = {
    fontSize: '16px',
    //boxShadow: '2px 2px 2px 0px rgba(33, 150, 243, 0.8)',
    FontFace: 'Arial',
    padding: '2px 2px 2px 5px',
    boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
    borderRadius: '4px',
    fontVariant: 'small-caps',
    backgroundColor: 'rgba(250, 250, 212, 0.7)',
    paddingRight: '6px',
    paddingBottom: '4px'
  }

  subTitleStyle = {
    fontSize: '15px',
    FontFace: 'Arial',
    fontVariant: 'small-caps',
    padding: '2px 2px 2px 5px',
    boxShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 2px 1px',
    borderRadius: '4px',
    marginTop: '8px',
    backgroundColor: 'rgba(250, 250, 60, 1)',
    width: 'fit-content',
    paddingRight: '6px',
    paddingBottom: '4px'
  }


  listStyle = { border: '1px solid black', padding: '8px', borderRadius: '4px', backgroundColor: 'rgba(250, 250, 210, .5)', marginLeft: '10px', height: 'fit-content' };

  subListStyle = { fontSize: '14px', paddingTop: '2px', paddingLeft: '2px' };

  subListStyle2 = { paddingTop: '2px', paddingLeft: '2px' };

  handleRadioButtonChange = (index) => {
    console.log("in handleRadioButtonChange " + index);

    this.setState({ selectedRBIndex: index });
  }

  render() {
    return (
      <div className='app-tab-item-container'>
        <div style={{ width: '100%' }}>
          <RadioButtons data={this.rbJson} OnStateChange={this.handleRadioButtonChange} />
        </div>

        <div className='app-tab-item-container-items double-extra-top'>
          <div className={`${this.state.selectedRBIndex === 0 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <BulletedTree title="" data={getArchServiceOfferingsJSON().data} titleStyle={this.titleStyle} subTitleStyle={this.subTitleStyle} subListStyle={this.subListStyle} showParentBullet={false} />
          </div>

          <div className={`${this.state.selectedRBIndex === 1 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '-24px' }}>
                <BulletedTree title="" subListStyle={this.subListStyle2} showParentBullet={false} data={getSkillMatrix().data} filter={this.techArch} />
                <BulletedTree title="" subListStyle={this.subListStyle2} showParentBullet={false} data={getSkillMatrix().data} filter={this.techStacks} listStyle={this.listStyle} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                <BulletedTree title="" subListStyle={this.subListStyle2} showParentBullet={false} data={getSkillMatrix().data} filter={this.techDesigns} />
              </div>
            </div>
          </div>

          <div className={`${this.state.selectedRBIndex === 2 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <BulletedTree title="" subListStyle={this.subListStyle2} showParentBullet={false} data={getSkillMatrix().data} filter={this.domains} />
          </div>

          <div className={`${this.state.selectedRBIndex === 3 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <BulletedTree title="" subListStyle={this.subListStyle2} showParentBullet={false} data={this.state.technicalDesignsJSON.data} />
          </div>

          <div className={`${this.state.selectedRBIndex === 4 ? 'app-tab-item-container-item visible' : 'app-tab-item-container-item invisible'}`}>
            <SampleDiagrams title="" />
          </div>
        </div>
      </div>
    );
  }
}

export default ArchitectureService;

