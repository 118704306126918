const ProgrammingServiceOfferingsJSON = {
    "data": [
        {
            "title": "Programming - Full Stack Development Services",
            "list": [
                "Develop projects in multiple tech stacks - .NET, Java, Python, Node.js, React, Angular, Vue.js, Express, etc.",
                "Analyze requirements, design and develop scalable software solutions.",
                "Write clean, maintainable code following best practices and coding standards.",
                "Designing modular and reusable architecture for flexibility and maintainability.",
                "evalaute and implement design patterns for efficient and scalable code.",
                "implement approriate architectural patterns for optimal performance.",
                "implement appropriate data structures and algorithms for optimal performance.",

                "Develop and maintain database schemas, queries, and stored procedures.",
                "Implement responsive and user-friendly front-end interfaces using HTML, CSS, and JavaScript.",
                "Optimize application performance and scalability through efficient coding techniques.",
                "Integrate third-party APIs and services to enhance application functionality.",
                "evalute 3rd party libraries and frameworks for suitability and compatibility.",
                "Conducting code refactoring to improve code quality.",

                "Build and deploy RESTful APIs to facilitate communication between systems.",
                "Implementing error handling mechanisms for robustness.",
                "Implementing logging and monitoring systems for real-time insights.",
                "Provide technical support and troubleshooting for software applications.",
            ]
        },
        {
            "title": "AI/ML Development Services",
            "children": [
                {
                    "title": "Develop Use Cases",
                    "list": [
                        "Develop supervised learning models for classification tasks such as sentiment analysis and spam detection.",
                        "Implement unsupervised learning algorithms for clustering data and identifying patterns in unlabelled datasets.",
                        "Design regression models for predicting continuous variables, such as house prices and stock prices, based on historical data.",
                        "Create classification models for identifying categories or labels for input data, such as email classification and document categorization.",
                        "Implement clustering algorithms for grouping similar data points together, such as customer segmentation and market basket analysis.",
                        "Develop object detection models using deep learning techniques for identifying and localizing objects within images or videos.",
                        "Design natural language processing (NLP) pipelines for tasks such as text summarization, named entity recognition (NER), and part-of-speech tagging.",
                        "Build sentiment analysis models to analyze and classify opinions expressed in text data, such as social media posts and customer reviews.",
                        "Create recommendation systems using collaborative filtering and content-based filtering techniques to personalize content and product recommendations.",
                        "Design AI-powered decision support systems to assist users in making informed choices based on data analysis.",
                    ]
                },
                {
                    "title": "Gen AI Development",
                    "list": [
                        "Designing and implementing conversational AI solutions using ChatGPT to automate customer support, lead generation, and other tasks.",
                        "Fine tuned models: Tailoring ChatGPT and other LLM models to specific domains or use cases by fine-tuning them on relevant datasets.",
                        "Integrating ChatGPT and other models into existing applications, websites, or platforms to enhance user interaction and engagement.",
                        "Developing NLP applications and tools using ChatGPT for tasks such as sentiment analysis, language translation, and text summarization.",
                        "Creating AI-powered content generation tools using ChatGPT to automate writing, coding, and other creative tasks.",
                        "Building AI-powered recommendation systems using ChatGPT to personalize content, product recommendations, and user experiences.",
                        "Developing NLP applications and tools using ChatGPT for tasks such as sentiment analysis, language translation, and text,summarization.",
                        "Designing and implementing conversational AI solutions using ChatGPT to automate customer support, lead generation, and other tasks.",
                    ]
                },
                {
                    "title": "NLP",
                    "list": [
                        "Custom NLP Model Development: Designing and training bespoke NLP models for specific use cases or domains.",
                        "NLP Consulting and Strategy: Providing expert guidance and advice on NLP implementation strategies and best practices.",
                        "Text Data Preprocessing: Cleaning, tokenizing, and normalizing text data to prepare it for NLP tasks.",
                        "NLP Model Evaluation and Benchmarking: Assessing NLP model performance using standard benchmarks and metrics.",
                        "Sentiment Analysis Solutions: Developing systems to analyze and classify text sentiment, enabling sentiment-driven insights.",
                        "Text Classification and Categorization: Building models to automatically categorize text data into predefined categories or labels.",
                        "Named Entity Recognition (NER): Creating models to identify and extract named entities such as people, organizations, and locations from text data.",
                        "Language Translation Services: Implementing machine translation systems to translate text between different languages, enabling multilingual support.",
                        "Text Generation and Summarization: Creating systems to generate coherent text passages or summaries from input data, facilitating content creation and synthesis.",
                        "NLP Integration and Deployment: Integrating NLP models into existing applications and deploying them to production environments for seamless integration and optimal performance."

                    ]
                }
            ]
        },
        {
            "title": "Frontend Development Services",
            "children": [
                {
                    "title": "User Experience & Interface Design",
                    "list": [
                        "Craft intuitive and visually appealing user interfaces to enhance user engagement.",
                        "Design seamless user flows for optimal navigation and interaction.",
                        "Create wireframes and prototypes to validate design concepts and gather feedback.",
                        "Optimize UI/UX design for different screen sizes and device resolutions.",
                        "Implement accessibility features to ensure inclusivity and compliance.",
                        "Designing responsive layouts for various devices and screen sizes.",
                        "Conducting user research to understand user needs and preferences.",
                        "Creating user personas to guide design decisions.",
                        "Conducting accessibility audits to ensure inclusivity.",
                        "Conducting usability testing to evaluate user satisfaction."
                    ]
                },
                {
                    "title": "UI Development",
                    "list": [
                        "Implement responsive and user-friendly front-end interfaces using HTML, CSS, and JavaScript.",
                        "Design and implement responsive web applications using modern front-end frameworks like React, Angular, and Vue.js.",
                        "Build beautiful and interactive user interfaces using custom HTML, CSS, libraries like bootstrap, Material UI, Vuetify, Fluent etc.",
                        "Build and deploy RESTful APIs to facilitate communication between systems.",
                        "Integrate third-party APIs and services to enhance application functionality."
                    ]
                }
            ]
        },
        {
            "title": "Backend Development Services",
            "children": [
                {
                    "title": "API Development",
                    "list": [
                        "Build and deploy RESTful APIs to facilitate communication between systems.",
                        "Utilize API management platforms to monitor, secure, and document APIs.",
                        "Implement GraphQL APIs for efficient data retrieval and manipulation."
                    ]
                },
                {
                    "title": "Data Management",
                    "list": [
                        "Develop and maintain database schemas, queries, and stored procedures.",
                        "evaluate and implement approriate database technologies like SQL, NoSQL, Data Warehouse, Data Lake, Graph, Timeseries, Vector etc.",
                        "Optimize database queries to enhance efficiency.",
                        "Utilize cloud-based storage solutions like Amazon S3 or Google Cloud Storage for data storage.",
                        "Implement cloud-based databases like Amazon RDS or Google Cloud SQL for data management."
                    ]
                },
                {
                    "title": "Cloud Computing",
                    "list": [
                        "Utilize cloud services like AWS Lambda or Google Cloud Functions for serverless computing.",
                        "Deploy applications using containerization technologies like Docker and Kubernetes.",
                        "Implement cloud-native architectures leveraging microservices and serverless paradigms."
                    ]
                },
                {
                    "title": "Cross-Platform Development",
                    "list": [
                        "Develop mobile apps that run seamlessly on multiple platforms like iOS and Android.",
                        "Utilize frameworks like React Native or Flutter to build cross-platform applications.",
                        "Write platform-agnostic code to reduce development time and effort.",
                        "Ensure consistent user experience across different operating systems.",
                        "Leverage native modules for platform-specific functionalities when needed."
                    ]
                }
            ]
        },
        {
            "title": "Auxiliary Services Development",
            "children": [
                {
                    "title": "Performance Optimization",
                    "list": [
                        "Implement caching mechanisms for improved performance.",
                        "Optimizing database queries to enhance efficiency.",
                        "Conducting performance profiling to identify optimization opportunities.",
                        "Optimizing hardware configurations to minimize latency.",
                        "Optimize app performance to minimize loading times and maximize responsiveness.",
                        "Use concepts of treeshaking, side effects, lazy loading, chunking, minification to optimize UI performance.",
                        "Implementing content delivery networks for faster content delivery.",
                    ]
                },
                {
                    "title": "Security",
                    "list": [
                        "Implement authentication and authorization mechanisms to secure applications.",
                        "Implementing encryption techniques to protect sensitive data.",
                        "Conducting security audits to identify vulnerabilities and risks.",
                        "Conducting penetration testing to identify security weaknesses.",
                    ]
                },
                {
                    "title": "Data Protection",
                    "list": [
                        "Implementing encryption techniques to protect sensitive data.",
                        "Conducting compliance assessments to ensure regulatory adherence.",
                        "Conducting data integrity checks to ensure data accuracy.",
                        "Implementing backup and restore procedures for data protection."
                    ]
                },

                {
                    "title": "NFRs",
                    "list": [
                        "Scalability: Design architecture for increased workload, utilizing cloud-based infrastructure for elastic scalability.",
                        "Reliability: Implement fault-tolerant systems with redundant components and failover mechanisms.",
                        "Availability: Design systems with high availability, utilizing load balancing and geo-replication.",
                        "Performance: Optimize system performance with caching mechanisms and performance testing.",
                        "Security: Implement robust security measures to protect against unauthorized access.",
                        "Maintainability: Design codebase for easy maintenance and updates, utilizing clean coding practices.",
                        "Usability: Ensure user-friendly interfaces and intuitive design for enhanced user experience.",
                        "Interoperability: Design systems to seamlessly integrate with third-party services and APIs.",
                        "Compliance: Ensure compliance with industry regulations and standards, implementing necessary security and privacy measures.",
                        "Resilience: Build systems resilient to failures, with proactive monitoring and recovery strategies.",
                        'Configurability: Design systems with configurable parameters for easy customization and adaptability.',
                    ]
                },
            ]
        },
        {
            "title": "Quality Assurance Services",
            "list": [
                "Conduct unit testing and debugging to ensure software reliability and stability.",
                "Perform load testing to ensure performance and scalability of applications.",
                "Conducting stress testing to evaluate system stability.",
                "Utilize automated testing tools and frameworks to streamline testing processes and improve efficiency.",
                "Conducting usability testing to enhance user satisfaction."
            ]
        },
        {
            "title": "Continuous Integration and Deployment Services",
            "list": [
                "Set up automated build and deployment pipelines for efficient development workflows.",
                "Integrate version control systems like Git for collaborative development.",
                "Automate testing processes to ensure code quality and reliability.",
                "Implement continuous deployment strategies for seamless app updates and releases.",
                "Utilize monitoring tools to track app performance and user feedback in real-time."
            ]
        },

        {
            "title": "Code Management Services",
            "list": [
                "Implementing version control systems for code management.",
                "Conducting code refactoring to improve code quality."
            ]
        },
        {
            "title": "Coding Best Practices",
            "list": [
                "Write clean, maintainable code following best practices and coding standards.",
                "Participate in code reviews to ensure code quality and adherence to standards.",
                "Implement responsive and user-friendly front-end interfaces using HTML, CSS, and JavaScript.",
                "Optimize application performance and scalability through efficient coding techniques."
            ]
        },
        {
            "title": "Documentation Services",
            "list": [
                "Create technical documentation, including user manuals and API documentation.",
                "Creating UML and other technical diagrams for system design and architecture.",
                "Produce detailed architectural blueprints and system diagrams for clarity and reference.",
                "Document API endpoints, data models, and interaction flows for efficient development.",
                "Generate user-friendly documentation and manuals to aid in product understanding and usage.",
                "Develop detailed wireframes and mockups to visualize app interfaces and user interactions."
            ]
        },
        {
            "title": "Professional Development Services",
            "list": [
                "Collaborate with cross-functional teams to deliver projects on time and within budget.",
                "Provide guidance and mentorship to junior developers in coding and best practices.",
                "Stay updated on industry trends and emerging technologies in software development."
            ]
        },
    ]
}

function getProgrammingServiceOfferingsJSON() {
    return ProgrammingServiceOfferingsJSON;
}

export default getProgrammingServiceOfferingsJSON;
