const AreasOfchildrenJSON = {
    "data": [
        {
            "title": "Core Areas of Expertise",
            "list": [
                "Develop Systems with architectures like monolithic, client-server, n-tier, layered, distributed, SOA, MVC, MVVM, Frameworks, Microservices, etc.",
                "Possess in-depth experience in several Tech stacks like .NET, NodeJS, Java, C++, Python, Mobility.",
                "Profcient in several programming languages like C#, VB.NET, ASP.NET, Java, HTML/CSS, Javascript, Typescript and PHP.",
                "Proficient with Web, Mobile App, Desktop, Systems development, API Development and Data related technologies.",
                "Very good exposure to Data Engineering, Data Architecture",
                "Proficient in several databases like SQL Server, Oracle, MySQL, PostgreSQL, MongoDB, Redis, etc.",
                "Good experience in ML/AI/NLP/GenAI - Recommendations, Conversational AI, ChatBOTs, predictive analysis, Intelligent assessments, analytics",
                "Good experience in modern cloud technologies like Azure and AWS.",
                "Strong skill set in databases, network, and user interface programming.",
                "Thorogh in several system NFRs like correctness, reliability, completeness, cohesive, coupling, performance, reusability, evolvability, customizability, configurability, scalability, availability, clustering and fault tolerance, portability, interoperability, optimizations, DR, etc."
            ]
        },
        {
            "title": "Technology Interests/Strengths",
            "list": [

                "Primary interests/strengths are in all Microsoft technologies like .NET Framework including .NET 5.0, .NET Core, ASP.NET Core, MVC and Web API, Xamarin, WCF, WPF, SQL Server and several Azure services like Storage, Compute, App Service, Search, Cognitive, Serverless Architecture, Logic Apps, Service Bus, Event Hub, Cosmos DB, Azure Redis Cache etc.",
                "Grew up learning from the initial Microsoft technologies like C++, VC++, MFC, COM, Win32 etc.",
                "Very Good experience in Java, J2EE, Java Springboot, Spring Cloud technologies, Hibernate, JPA, JMS, JSP, Servlets, Struts, etc.",
                "Very Good experience in Frontend technologies like Angular, React and Vue JavaScript frameworks, Angular Components, Fluent, Vuetify, Ionic, BootstrapVue and Telerik Web Frameworks, Flutter for Mobile App, NodeJS and several NPM packages.",
                "Very Good Experience in Data Architectures - SQL, NoSQL, Redis, Caching, Data Warehousing Data Lake",
                "Good Exposure in ML/AI/NLP/GenAI - Recommendations, Conversational AI, ChatBOTs, predictive analysis, Intelligent assessments, analytics",]
        },
        {
            "title": "Delivery Management and Customer Success",
            "list": [
                "Experienced in software engineering methodologies like Iterative, Agile, Test Driven Development (TDD), Extreme Programming, and Continuous Integration. Extensive knowledge of DevOps and Azure DevOps",
                "Very good exposure to all phases of software development, aka of Inception, Elaboration, Construction and Transition.",
                "Good experience in Project, Process and People management.",
                "Have Played Several Roles - client interaction, ideation, project planning, UX/UI design, solutioning, architecting, coding, testing, release and change management, marketing, customer relationship, support, corporate development, budgeting, Recruitment and other HR activities, DevOps, Tech Writing, L&D, IT Infrastructure.",
                "Formed and Managed Customer Success Teams - Pre Sales, Demos, RFPs, System Studies, SoW, Onboarding. Customizations, Implementations, Technical Support, Customer Relationship Management",
            ]
        },
        {
            "title": "Other Areas of Expertise",
            "children": [
                {
                    "title": "Leadership",
                    "list": [
                        "I have extensive experience in forming, building, and mentoring new teams. Very good exposure to best practices for hiring, developing, and retaining good talent.",
                        "I have been a part of the leadership team in my previous organizations and have been involved in strategic planning, budgeting, corporate development, and other leadership activities."
                    ]
                },
                {
                    "title": "Cross Functional Roles played",
                    "list": [
                        "Played several roles like Business Analyst, Solution Architect, UX/UI Designer, Project Manager, Product Manager, Pre-Sales, Customer Success, Corporate Development, etc.",
                        "Vast experience in creating hiring strategy, processes and best practices for hiring, developing, and retaining good talent.",
                        "Mananged QA Teams and implemented QA Automation frameowrks and best practices.",
                        "Good exposure to L&D, Tech Writing, etc.",
                        "Have helped organizations in completing Audits and Certifications like ISO, CMMI, HIPAA, etc. and performing VAPTs.",
                        "Have setup OKRs, KPIs, KRAs for entire organization and for individual teams and employees across several functions.",
                        "Have setup and managed IT Infrastructure, Data Centers, Cloud, DR, Security, etc.",
                        "I have lead DevOps teams and implemented CI/CD, Azure DevOps, Jenkins, etc.",
                    ]
                },
                {
                    "title": "Other Areas",
                    "list": [
                        "Domain Agnostic - Worked in several domains like Healthcare, Retail, Supply Chain Management, Education, Patient Health Records, Medical Imaging, Digit Marketing, Data Extraction, Data Mining, Real Estate, etc.",
                        "Ability to quickly learn, adapt and incorporate new technologies.",
                        "Good communication and written skills. Collaborative development; Team player.",
                        "Worked on projects both Offshore (18 years, India) and Onsite (US, Singapore, UK, 8 years).",
                        "I have extensive experience in forming, building, and mentoring new teams. Very good exposure to best practices for hiring, developing, and retaining good talent."
                    ]
                }
            ]
        }
    ]
}

function getAreasOfchildrenJSON() {
    return AreasOfchildrenJSON;
}

export default getAreasOfchildrenJSON;

