import React, { Component } from 'react';
import AppBodyInnerHeader from './AppBodyInnerHeader';
import RadioButtons from '../Controls/RadioButtons';
import ScrollToTop from '../Controls/ScrollToTop';

import TechnicalArticles from './FreeContent/TechnicalArticles';
import FreeTemplates from './FreeContent/FreeTemplates';
import TechnicalGuidelines from './FreeContent/TechnicalGuidelines';
import Projects from './Services/Projects';
import ContentSubjects from './Services/ContentSubjects';

import './AppBodyCommon.css';
import './FreeContent.css';
import './Services/ContentSubjects.css'

class FreeContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRBIndex: 0
    };
  }

  jsonData = [
    {
      "label": "Templates",
      "text": "Access a library of customizable templates to streamline your work."
    },
    {
      "label": "Projects",
      "text": "Explore sample project implementations and prototypes demonstrating various technical frameworks, libs."
    },
    {
      "label": "Guidelines",
      "text": "Discover comprehensive guidelines to navigate complex processes effortlessly."
    },
    {
      "label": "Articles",
      "text": "Engage with insightful and thought-provoking articles  for inspiration, collected over a period of time."
    },
    {
      "label": "Software Realm 101",
      "text": "Deepen your understanding of software development with expert insights."
    }
  ];

  componentDidMount() {
    //this.setURLPath();
  }

  handleRadioButtonsStateChanges = (index) => {
    console.log("in handleRadioButtonsStateChanges " + index);

    this.setState({ selectedRBIndex: index });
  }

  
  setURLPath = (tab) => {
    
    var url = window.location.origin + "/free-content";

    console.log("+++++++++in setURLPath on free-content with url: " + url);

    window.history.pushState({}, '', url);
  }

  render() {
    return (
      <div>
        <AppBodyInnerHeader title="FREE ARTEFACTS" summary="Explore and download a plethora of <span style='color: blue; font-style: oblique;  font-weight: 500'>free artefacts, templates, documents, content & code snippets for 1000+ topics etc.</span>"
          controlsComponent={RadioButtons} data={this.jsonData} OnStateChange={this.handleRadioButtonsStateChanges} />

        <div className='app-tab-items'>
          <div className={`${this.state.selectedRBIndex === 0 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <FreeTemplates />
          </div>

          <div className={`${this.state.selectedRBIndex === 1 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <div className='app-tab-item-container'>
              <div className='app-tab-item-container-items zero-top'>
                <div className='app-tab-item-container-item'>
                  <Projects />
                </div>
              </div>
            </div>
          </div>

          <div className={`${this.state.selectedRBIndex === 2 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <TechnicalGuidelines />
          </div>

          <div className={`${this.state.selectedRBIndex === 3 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <TechnicalArticles />
          </div>

          <div className={`${this.state.selectedRBIndex === 4 ? 'app-tab-item visible' : 'app-tab-item invisible'}`}>
            <div className='app-tab-item-container'>
              <div className='app-tab-item-container-items zero-top'>
                <div className='app-tab-item-container-item'>
                  <ContentSubjects />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop></ScrollToTop>
      </div>
    );
  }
}

export default FreeContent;